/* BLOCK: Site Header */

.site-header {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.24), 0 0 7px rgba(0, 0, 0, 0.12);
  z-index: 1030;
  transition: width .25s;
  @include rmin(992) {
    position: fixed; //relative
    box-shadow: none; }

  &.menu-open {
    @include r(992 - .02) {
      width: 250px;
      width: (250 / 320) * 100%; } }

  &__row {
    align-items: center;
    @include rmin(992) {
      align-items: stretch; } }

  &__top {
    position: relative;
    background-color: #fff;
    z-index: 99;
    @include rmin(992) {
      border-bottom: 1px solid #e6e8f1; } }

  &__mobile-controls-list {
    list-style-type: none;
    padding: 6px 8px 3px;
    margin: 0;
    display: flex;
    @include rmin(992) {
      display: none; } }
  &__mobile-control-item {
    margin: 0; }


  &__search-block {
    width: 100%;
    @include rmin(992) {
      display: none;
      width: 270px;
      padding: 15px;
      border-right: solid 1px rgba(#3d57aa, .15); } }

  &__account-block {
    /*display: none*/
    @include rmin(992) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 125px;
      padding: 5px 15px;
      border-right: solid 1px rgba(#3d57aa, .15); } } }

.yalin-navbar-toggler {
  width: 39px;
  height: 33px;
  margin-right: 7px;
  padding: 10px 10px;
  border: none;
  line-height: 14px;
  flex-shrink: 0;
  background-color: transparent;
  @include rmin(992) {
    display: none; } }

.triple-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
  transform: scale(1.3);
  transition: all .3s ease-in;
  // +rmin($tablet-bp)
  //height: 3px
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border-radius: 0;
    background-color: #0b417a;
    transform-origin: center;
    @include gpu;
    transition: all .3s ease-in;
    // +rmin($tablet-bp)
 }    //height: 3px
  &:before {
    transform: rotate(45deg); }
  &:after {
    transform: rotate(-45deg); }

  .collapsed & {
    background-color: #0b417a;
    transform: scale(1);
    &:before {
      transform: translateY(-5px);
      // +rmin($tablet-bp)
 }      //transform: translateY(-10px)
    &:after {
      transform: translateY(5px);
      // +rmin($tablet-bp)
 } } }      //transform: translateY(10px)

.yalin-brand {
  margin-left: auto;
  margin-right: 2px;
  padding-top: 7px;
  padding-bottom: 9px;
  @include rmin(992) {
    padding: 8px 15px 13px;
    margin-right: 6px;
    margin-left: auto; }
  img {
    display: block;
    max-height: 50px;
    width: auto; } }

.mobile-header-btn-search {
	display:none !important {} }

.mobile-header-btn {
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px;
  border: none;
  background: transparent none;
  overflow: hidden;
  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
    line-height: 1;
    text-decoration: none;
    fill: #435f93; }
  .msg-count {
    @include msgCount; } }

.header-exit-btn {
  margin: 0;
  max-width: 150px;
  padding: 8px 20px;
  border: none;
  // border: 1px solid rgba(184, 191, 216, 0.1)
  border-radius: 20px;
  background: rgba(184, 191, 216, 0.1) none;
  color: #003874;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all .25s; }

// search form
.header-search {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  // align-items: stretch
  line-height: 1;

  &__input {
    flex-grow: 1;
    padding: 9px 1px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    &::placeholder {
      color: #8c8c8e; } }

  &__submit {
    border: none;
    background-color: transparent;
    flex: 0 0 44px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    svg {
      display: inline-block;
      width: 20px;
      height: 20px;
      fill: #979797; } } }

