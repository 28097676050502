$maxPageWidth: 1366; //1920
//$sidebarWidth: 266.5
$sidebarWidth: 208;


// Plain colors
$mainDark: #464646;

// Fonts
// $mainFont: 'Almoni Neue', sans-serif
$mainFont: 'Almoni DL AAA', sans-serif;

// Breakpoints

$mobile-bp: 576;
$tablet-bp: 992;
$desktop-bp: 1200;
$xl-bp: 1920;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }
