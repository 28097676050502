.content-sheet {
    box-shadow: none;
}

.disableButton {
    border-color: rgba(184, 191, 216, 0.1);
    background-color: rgba(184, 191, 216, 0.1);
    color: #0b417a;
    box-shadow: none;
}

.custom-select__item:hover {
    width: 350px;
    color: #0b417a;
    font-weight: bold;

    @media (max-width: 992px) {
        width: 350px;
        color: #0b417a;
        font-weight: bold;
    }
}

.custom-select__item {
    width: 350px;
    color: #0b417a;
    font-size: larger;
    font-weight: bold;

    @media (max-width: 992px) {
        font-size: initial;
        color: #0b417a;
        font-weight: bold;
    }
}
.custom-select__input:focus, .custom-select__input:hover {
    border-color: #435f93;
    background-color: #f8f9fa;
    outline: none;
}
.custom-select__input {
    width: 350px;
    font-size: larger;
    font-weight: bold;

    @media (max-width: 992px) {
        width: 100%;
        font-size: initial;
        font-weight: bold;
    }
}