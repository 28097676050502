/* General site layout */

body {
  display: flex;
  flex-direction: column; }

#root {
  filter: none !important; }

.site-wrapper {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  // wrapper option
  // +rmin($maxPageWidth)
  //   max-width: $maxPageWidth+px
  //   margin-left: auto
  //   margin-right: auto
 }  //   box-shadow: 0 0 15px #bbb

// main
.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include rmin(992) {
    padding-right: $sidebarWidth+px; } }

main {
  flex-grow: 1; }


/* Centering page content */
.content-wrapper {
  width: 100%;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  &--sheet {
    padding-left: 0;
    padding-right: 0;
    @include rmin(992) {
      padding-left: 15px;
      padding-right: 15px; } } }


