.display-pdf-container {
    text-align: left;

    // .mobile__pdf__container #viewerContainer
    //     position: absolute
    //     overflow: scroll
    //     width: 100%
    //     top: 90px
    //     bottom: 90px
    //     left: 0
    //     right: 0
    // .mobile__pdf__container__header
    //     display: none

    .pdf-file {
        overflow: scroll;
        height: 384px;
        position: relative;

        .mobile__pdf__container {
            #viewerContainer {
                position: absolute;
                overflow: scroll;
                width: 100%;
                top: 0;
                left: 0;
                right: 0; }


            #loadingBar {
                display: none; }

            footer {
                position: absolute; } } }


    .action-buttons {
        border: none;
        box-shadow: none;
        @include rmin(992) {
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                justify-content: flex-start !important; } }
        a:hover {
            text-decoration: none; }
        &__btn {
            width: auto;
            padding: 5px 5px 5px 5px;
            font-size: 13px;
            line-height: 16px;
            &:before {
                margin-left: 0; }
            &--pdf:before {
                background-size: 15px 20px; } } }
    .personal-update__block-header {
        margin-bottom: 10px;
        text-align: right; }

    .pdf-container {
        width: fit-content !important;
        height: fit-content !important;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: auto !important;
            height: auto !important;
            text-align: right !important; } }
    .btn {
        float: left;
        margin: 10px 0;
        display: block;
        width: 100%;
        padding: 8px 10px 7px;
        border: 1px solid #003874;
        border-radius: 20px;
        background: #003874;
        color: #ffffff;
        font-size: 15.5px;
        line-height: 1;
        font-weight: 700;
        box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6);
        position: relative;
        @include text-overflow;
        transition: all .3s;
        @include rmin(992) {
            width: 180px;
            height: 44px;
            max-width: 100%;
            padding: 9px 9px;
            min-width: 122.5px;
            border-radius: 20px;
            font-size: 20px;
            line-height: 1;
            box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6); }
        &:focus {
            outline: none; }
        &:focus,
        &:hover, {
            background-color: #fff;
            color: #003874; } } }

