.personal-update {
  padding: 25px 36px 32px 36px;
  @include rmin(992) {
    padding: 25px 36px 32px 36px; }
  .yalin-checkbox__label, a {
    font-size: 15px;
    font-weight: bold;
    color: #003874; }

  &__block {
    @include r(992 - .02) {
      &:last-child {
        .personal-update__details {
          padding-bottom: 0;
          border-bottom: solid 1px rgba(209, 216, 245, 0.6); } } } }

  &__block-header {
    padding-bottom: 11px;
    border-bottom: 2px solid #11377638;
    @include rmin(992) {
      padding-bottom: 9px; } }

  &__heading {
    margin-bottom: 10px;
    line-height: 1;
    font: Bold 12px/12px Almoni DL AAA;
    font-size: 22px;
    letter-spacing: 0;
    color: #464646;
    @include rmin(992) {
      font-size: 24px;
      letter-spacing: .003em; } }
  &__sub-heading {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1;
    font: Bold 12px/12px Almoni DL AAA;
    font-size: 14px;
    letter-spacing: 0;
    color: #464646;
    @include rmin(992) {
      letter-spacing: .003em; }
    &.personal {
      font-size: 15px;
      font-weight: 800; } }


  &__change-btn {
    // float: left
    display: block;
    min-width: 275 / 2 +px;
    // margin-bottom: -2px
    margin-right: auto;
    padding: 6px;
    border: 1px solid #003874;
    border-radius: 16px;
    background-color: #003874;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(67, 95, 147, 0.6);
    @include text-overflow;
    cursor: pointer;
    transition: all .3s;
    @include rmin(992) {
      min-width: 184px;
      margin-bottom: 3px;
      padding: 5px 6px;
      font-size: 17px;
      line-height: 18px; }
    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874;
      text-decoration: none; } }


  &__details {
    padding-top: 12px;
    padding-bottom: 24px;
    @include rmin(992) {
      padding-top: 14px;
      padding-bottom: 32px; } }

  &__list {
    list-style-type: none;
    padding-right: 0;
    margin-bottom: 0;
    display: table;
    width: 100%;
    margin-bottom: 11px; }

  &__item {
    display: table-row; }
  &__item-type,
  &__item-value {
    display: table-cell;
    vertical-align: top;
    padding-top: 9px;
    padding-bottom: 9px;
    @include rmin(992) {
      padding-top: 9px;
      padding-bottom: 10px; } }

  &__item-type {
    position: relative;
    width: 252 / 573.5 * 100%;
    // padding-right: 33px
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: -.0175em;
    @include rmin(992) {
      width: 43%;
      padding-right: 2px;
      // padding-right: 40px
      font-size: 15px;
      line-height: 1; } }

  &__item-value {
    font-size: 12px;
    line-height: 12px;
    @include rmin(992) {
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -.0175em; }
    &.number,
    .number {
      font-size: 10.5px;
      font-weight: 600;
      letter-spacing: -.02em;
      @include rmin(992) {
        font-size: 12px;
 } }        // letter-spacing: -.0175em
    a {
      color: inherit; } } }


.update-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;



  &__body {
    width: 100%;
    height: 100%;
    @include rmin(763) {
      .col-12 {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; } } }


  &__footer {
    width: 100%;
    @include rmin(992) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column; } }

  &__terms {
    position: relative;
    margin-bottom: 30px;
    text-align: right;
    color: #4c4c4c;
    width: 100%;

    .checkbox_style_footer_update_details {
      margin-right: -10px !important; }

    @include rmin(992) {
      margin-right: 10px;
      font-size: 14px; }
    a {
      margin-right: .3em;
      color: #0b417a;
      text-decoration: underline;
      letter-spacing: -.0175em;
      transition: all .25s; } }



  &__form-group {
    max-width: 750px;
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    @include rmin(992) {
      margin-bottom: 22px; }
    // &:last-of-type
    //margin-bottom: 0
    .update-form__field ~ .update-form__label {
      position: absolute;
      top: -5px;
      right: 5px;
      -webkit-transition: all 300ms linear;
      transition: all 300ms linear;
      font: Bold 15px/12px Almoni DL AAA;
      color: #003874; } }

  &__label {
    width: 100%;
    margin: 0;
    font: Bold 11px/12px Almoni DL AAA;
    font-size: 17px;
    color: #003874; }

  &__radio-label {
    margin-top: 8px;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font: Bold 16px/16px Almoni DL AAA;
    color: #113776;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    #gender-error {
      width: 160px;
      max-width: none;
      white-space: normal;
      right: 0; }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked  ~ .checkmark:after {
        display: block; } }
    .checkmark {
      position: absolute;
      top: 0;
      right: 0;
      width: 17px;
      height: 17px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 50%;
      border: 2px solid #6D7278;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        right: 2px; } }

    &::hover input ~ .checkmark {
      background-color: #ccc; }

    input:checked ~ .checkmark:after {
      display: block; }

    .checkmark:after {
	    width: 9px;
	    height: 9px;
	    border-radius: 50%;
	    background: #1A3678 0% 0% no-repeat padding-box; } }





  &__tool-tip {
    position: absolute;
    left: -8px;
    top: 23px;
    border: none;
    background: none;
    z-index: 10;
    &:focus {
      outline: none; }
    @include rmin(992) {
      left: 49px;
      top: 25px; } }

  &__field {
    width: 100%;
    padding: 20px 0px 0px;
    border: none;
    border-bottom: 2px solid #6D7278;
    background-color: #fff;
    background-image: linear-gradient(transparent, transparent);
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #6D7278;
    &[name='email'],
    &[name='mobile'] {
      width: 96%;
      @include rmin(992) {
        width: 234px; } }
    &.number {
      font-size: 13px; }
    @include rmin(992) {
      width: 253px;
      padding-top: 20px;
      font-size: 16px;
      line-height: 18px; }
    &:focus {
      outline: none; }
    &:focus ~ .update-form__label,&.move-top ~ .update-form__label {
      top: -5px;
      font-size: 15px;
      z-index: 0; }

    &:disabled {
      background: #d3d4d685 0% 0% no-repeat padding-box;
      border: none;
      padding-top: 1px;
      margin-top: 20px;
      padding-right: 4px;
      padding-bottom: 6px;
      width: 100%;
      @include rmin(992) {
        width: 253px; } }

    &::placeholder {
      color: #6D7278;
      opacity: 1; }
    &::-ms-input-placeholder {
      color: #6D7278; }
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none !important {
       margin: 0; } }

    .select-selected {
      cursor: pointer;
      height: 20px;
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        margin: 0;
        border: none;
        border-bottom: 2px solid #003874;
        border-left: 2px solid #003874;
        position: absolute;
        top: 30px;
        left: 12px;
        transform: translateY(-75%) rotate(135deg);
        transition: all .3s;
        @include rmin(992) {
          left: 62px; } }
      &.hide:after {
        transform: translateY(-75%) rotate(-45deg); } }
    .select-items {
      direction: inherit;
      position: absolute;
      padding: 0;
      margin: 0;
      top: 100%;
      right: 5px;
      z-index: 101;
      width: 97%;
      text-align: right;
      border-radius: 12px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
      @include rmin(992) {
        width: 253px; }
      &.hide {
        display: none; }
      .item {
        margin-bottom: 5px;
        width: 100%;
        padding: 8px 28px 8px 10px;
        color: #4c4c4c;
        font-size: 15px;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        display: block;
        &:hover {
          background-color: rgba(229, 235, 241, 0.69);
          color: #0b417a;
          cursor: pointer; } } }
    .date {
      &__container {
        display: flex; }

      &__field {
        width: 32%;
        text-align: center;
        font: Bold 17px/16px Almoni DL AAA;
        color:#6D7278 {}
        border: none;
        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none !important; }
        &[type=number] {
          -moz-appearance:textfield {

           margin: 0; } }
        &:focus {
          outline: none; } } } }

  &__upload {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .text {
      margin-left: 8px;
      color: #003874;
      font: Bold 12px/12px Almoni DL AAA;
      font-size: 15px; }
    .remove {
      font-size: 20px;
      color: #c33535;
      cursor: pointer; } }
  &__upload-container {
    position: relative;
    display: inline-block;
    label.error {
      overflow: unset; }
    .upload-input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 10;
      width: 116px; }

    .upload-btn {
      display: block;
      width: 116px;
      height: 33px;
      border: 1px solid #E5E5E5;
      border-radius: 20px;
      background-color: #fff;
      color: #063D78;
      font-size: 15px;
      line-height: 1;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      position: relative;
      cursor: pointer;
      @include text-overflow;
      transition: all .3s;
      @include rmin(992) {
        width: auto;
        max-width: 100%;
        min-width: 122.5px;
        border-radius: 20px;
        font-size: 15px;
        line-height: 1; }
      &:focus {
        outline: none; }
      &:focus,
      &:hover {
        background: #063D78;
        color: #ffffff; }
      span {
        font-size: 25px;
        margin-left: 3px; } } }


  &__submit-container {
    margin-top: 57px;
    @include rmin(992) {
      margin-top: 0;
      margin-right: auto; } }
  &__submit {
    display: block;
    width: 100%;
    padding: 8px 10px 7px;
    border: 1px solid #003874;
    border-radius: 20px;
    background: #003874;
    color: #ffffff;
    font-size: 15.5px;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6);
    position: relative;
    &:after {
     content: '';
     width: 10px;
     height: 10px;
     margin: 0;
     border: none;
     border-bottom: 2px solid #ffffff;
     border-left: 2px solid #ffffff;
     position: absolute;
     top: 11px;
     left: 18px;
     transform: rotate(45deg); }

    @include text-overflow;
    transition: all .3s;
    @include rmin(992) {
      width: 180px;
      height: 44px;
      max-width: 100%;
      padding: 9px 9px;
      min-width: 122.5px;
      border-radius: 20px;
      font-size: 20px;
      line-height: 1;
      box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6);
      &::after {
        top: 17px; } }
    &:focus {
      outline: none; }
    &:focus,
    &:hover, {
      background-color: #fff;
      color: #003874; }
    &:hover::after {
      border-bottom: 2px solid #003874;
      border-left: 2px solid #003874; }
    &.back-btn {
      &::after {
        right: 18px;
        transform: rotate(226deg); } } } }

.tooltip {
  @include rmin(992) {
    top: 20px !important;
    left: -56px !important;
    z-index: 0; } }
.tooltip-inner {
  background: #F7F8FB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  text-align: right;
  font: 800 14px/12px Almoni DL AAA;
  letter-spacing: -0.12px;
  color: #113776;
  width: 105px;
  height: auto; }

.city-list {
  list-style: none;
  padding: 0;
  direction: inherit;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 100%;
  right: 5px;
  z-index: 101;
  width: 97%;
  text-align: right;
  border-radius: 12px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  // min-height: 180px
  // height: auto
  overflow-x: auto;
  height: auto;
  max-height: fit-content;
  &.empty-list {
    height: 0; }
  &.height-list {
    height: 180px;
    max-height: 180px; }
  @include rmin(992) {
    width: 253px; }

  .city {
    margin-bottom: 5px;
    width: 100%;
    padding: 8px 28px 8px 10px;
    color: #4c4c4c;
    font-size: 15px;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    &:hover ,&.active {
      background-color: rgba(229, 235, 241, 0.69);
      color: #0b417a;
      cursor: pointer; } } }
.text-error {
  color: #e83a3a; }

.messageLabel {
  width: 100%;
  margin: 0;
  font-size: 14px !important;
  color: #003874;
  font:Bold 11px/12px Almoni DL AAA {} }

.disable-date {
  background: #d3d4d685 0% 0% no-repeat padding-box; }

.back_button {
   &:after {
     content: '';
     width: 0px;
     height: 0px;
     margin: 0;
     border: none;
     position: absolute;
     top: 11px;
     left: 18px; } }

.back_button_style {
   @include rmin(992) {
      display: flex;
      flex-direction: row-reverse; } }

.back_container {
  margin-top: 20px;
  @include rmin(992) {
     margin-left: 20px;
     margin-top: 0px; } }



