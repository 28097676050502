// }

.all-image-container {

    @media (max-width: 992px) {
        // height: 573px;
        // top: 256px;
        // // background: #EFF4F8;
    }

    .all-actions-page {
        width: 355px;
        height: 70px;
        top: 154px;
        left: 1127px;
        gap: 0px;
        margin-bottom: 0;
        font-size: 70px;
        font-weight: 700;
        letter-spacing: -.0175em;
        line-height: 70px;
        color: #04569E;

        @media(max-width :992px) {
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.03em;
            height: 28px;
            top: 178px;
            left: 233px;
            color: #04569E;
            position: inherit !important;
            margin-top: 40px;
        }
    }

    .wrap-all-img-desc {
        @media (max-width: 992px) {
            margin-top: 0%;

        }
    }

    .wrap-img {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            justify-content: space-evenly;

        }

        .image-desc {
            width: 160px;
            margin: 10px;
            cursor: pointer;

        }

    }

    .image-mobile {
        @media (max-width: 992px) {
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
        }
    }



}