.generic-popup-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(158, 155, 155, 0.4);
    z-index: 999999;
    padding: 60px 40px;
    display: none;

    .generic-popup-container {
        position: relative;
        width: 426px;
        min-height: 220px;
        border-radius: 8px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        margin: auto;
        padding: 30px;
        align-self: center;
        display: flex;
        flex-direction: column;

        &.scroll-generic-popup-container {
            @media (max-width: 992px) {
                height: 100%;
                max-height: 700px;
            }
        }

        &.remove-background,
        &.remove-background-birth {
            box-shadow: none;
            background-color: transparent;
            padding: 0px;
        }

        .title {
            font-size: 25px;
            font-weight: bold;
            line-height: 0.76;
            color: #464646;
            margin-bottom: 15px;
        }

        .text {
            font-size: 20px;
            line-height: 1.15;
            letter-spacing: 0.4px;
            color: #464646;

            &.scroll-text {
                @media (max-width: 992px) {
                    overflow-y: scroll;
                }
            }

            &.image-background-size {
                width: 500px;
                height: 312.5px;
                background-image: url("../../img/A.jpg");
                background-size: cover;
                /* or 'contain' depending on the desired effect */
                background-repeat: no-repeat;
                /* Prevent the image from repeating */
                background-position: center;

                @media (max-width: 992px) {
                    width: 332px;
                    height: 415px;
                    background-image: url("../../img/A1.jpg");
                    background-size: cover;
                    /* or 'contain' depending on the desired effect */
                    background-repeat: no-repeat;
                    /* Prevent the image from repeating */
                    background-position: center;
                }
            }


            &.image-background-birth-popup {
                width: 500px;
                height: 312.5px;
                background-image: url("../../img/popup_yom_huledet_2022_d2.jpg");
                background-size: cover;
                /* or 'contain' depending on the desired effect */
                background-repeat: no-repeat;
                /* Prevent the image from repeating */
                background-position: center;

                @media (max-width: 992px) {
                    width: 332px;
                    height: 415px;
                    background-image: url("../../img/popup_yom_huledet_2022_d.jpg");
                    background-size: cover;
                    /* or 'contain' depending on the desired effect */
                    background-repeat: no-repeat;
                    /* Prevent the image from repeating */
                    background-position: center;
                }
            }




        }

        .buttons-container {
            margin-top: 10px;
            text-align: left;
            display: flex;
            flex-direction: row-reverse;


            .confirm_btn {
                border-radius: 16px;
                box-shadow: 0 2px 6px 0 rgba(67, 95, 147, 0.6);
                background-color: #003874;
                font-size: 17px;
                font-weight: bold;
                line-height: 1.41;
                color: #ffffff;
                border: 0;
                padding: 5px 15px;
                text-decoration: none;
                transition: all .3s;

                &:focus,
                &:hover {
                    background-color: #fff;
                    color: #003874;
                }
            }

            .secondary_btn {
                border-radius: 20px;
                background-color: rgba(184, 191, 216, 0.1);
                font-size: 14px;
                font-weight: bold;
                line-height: 1.71;
                letter-spacing: 0.54px;
                color: #0b417a;
                border: 0;
                padding: 5px 15px;
                text-decoration: none;
                transition: all .3s;
                margin-left: 12px;

                &:focus,
                &:hover {
                    background-color: rgba(184, 191, 216, 0.3);
                    box-shadow: 0 2px 6px 0 rgba(67, 95, 147, 0.6);
                }
            }
        }

        .buttons-container.remove-color,
        .buttons-container.remove-color-birth {
            display: inline;
            margin-top: -100px;
            z-index: 2;
            border: none;


            .confirm_btn {
                background-color: transparent;
                box-shadow: none;
                margin-left: 209px;
                border: none;
            }

            .secondary_btn {
                background-color: transparent;
                box-shadow: none;
                margin-left: 209px;
                border: none;

            }

            &:focus,
            &:hover {
                background-color: transparent;
                color: transparent;
            }
        }


    }

    .close-icon {
        display: none;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 20px;
        font-size: 12px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 10px;

        &.show-close-button {
            display: inline;
            top: 35px;
            left: 380px;

            @media (max-width: 992px) {
                left: 290px
            }

            .vertical-line {
                background-color: #ffffff;
            }

            .horizontal-line {
                background-color: #ffffff;
            }
        }



        .vertical-line {
            background-color: #003874;
            width: 21px;
            height: 3px;
            border-radius: 4px;
            transform: rotate(50deg);
            transition: 0.4s;
            position: absolute;
            left: 0;
            bottom: 0px;
        }

        .horizontal-line {
            position: absolute;
            background-color: #003874;
            width: 21px;
            height: 3px;
            bottom: 0px;
            border-radius: 4px;
            left: 0;
            transform: rotate(-50deg);

        }
    }
}