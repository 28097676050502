/* PAGE: REPORTS */

.contact-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  // padding-bottom: 35px
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.contact-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 16px;
  padding-bottom: 66px;
  @include rmin(992) {
    padding-top: 95px;
    padding-bottom: 26px; }

  &__heading {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      padding-right: 33px;
      font-size: 50px;
      margin-bottom: 8px; } } }


.contact-data {
  padding: 19px 15px 47px 17px;
  @include rmin(992) {
    padding: 32px 42px 100px 40px; }

  &__header {
    padding-bottom: 13px;
    border-bottom: solid 1px rgba(209, 216, 245, 0.6); }

  &__heading {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -.0175em;
    color: #10457d;
    @include rmin(992) {
      font-size: 22px;
      letter-spacing: .003em;
      color: #0b417a; } }

  &__header-text {
    max-width: 270px;
    margin-left: auto;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -.0175em;
    @include rmin(992) {
      max-width: none;
      font-size: 18px;
      line-height: 1.2; } }

  &__details {
    padding-top: 20px;
    padding-bottom: 20px;
    @include rmin(992) {
      padding-top: 28px;
      padding-bottom: 38px; } }

  &__list {
    list-style-type: none;
    padding-right: 0;
    margin-bottom: 0;
    display: table;
    width: 100%;
    margin-bottom: 11px; }

  &__item {
    display: table-row; }
  &__item-type,
  &__item-value {
    display: table-cell;
    vertical-align: top;
    padding-top: 7px;
    padding-bottom: 8px;
    @include rmin(992) {
      padding-top: 9px;
      padding-bottom: 9px; } }

  &__item-type {
    position: relative;
    width: 80px;
    padding-right: 33px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      width: 130px;
      padding-right: 40px;
      font-size: 18px;
      line-height: 1; }
    svg {
      position: absolute;
      right: 2px;
      // top: 0
      width: 14px;
      height: 13px;
      fill: currentColor;
      @include rmin(992) {
        width: 18px;
        height: 18px; } } }

  &__item-value {
    font-size: 12px;
    line-height: 12px;
    @include rmin(992) {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -.0175em; }
    &.number,
    .number {
      font-size: 10.5px;
      font-weight: 600;
      letter-spacing: -.005em;
      @include rmin(992) {
        font-size: 15px;
        letter-spacing: -.0175em; } }
    a {
      color: inherit; }
    .notice {
      display: block;
      margin-top: 5px;
      font-size: 10px;
      line-height: 1.25;
      font-style: normal;
      letter-spacing: -.0175em;
      opacity: .56;
      @include rmin(992) {
        max-width: 585px;
        margin-top: 7px;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -.004em; } } }


  &__form-block {
    border: solid 1px #e5e5e5;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    @include rmin(992) {
      border: none;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2); } } }

/* Contact page form */
.cp-form {
  padding: 13px 16px 21px 14px;
  @include rmin(992) {
    padding: 26px 18px 31px; }

  &__heading {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: .0025em;
    color: #0b417a;
    @include rmin(992) {
      margin-bottom: 29px;
      font-size: 22px; } }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 3px;
    @include rmin(992) {
      margin-left: -17px;
      margin-right: -17px;
      margin-bottom: 16px; } }

  &__col-inputs {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    @include rmin(768) {
      flex: 0 0 50%;
      max-width: 50%; }
    @include rmin(992) {
      padding-left: 17px;
      padding-right: 17px;
      flex: 0 0 381px;
      max-width: 381px; } }

  &__col-fill {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    @include rmin(768) {
      flex: 1 1 auto;
      width: auto;
      max-width: 100%; }
    @include rmin(992) {
      padding-left: 17px;
      padding-right: 17px; } }


  &__form-group {
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 5px;
    @include rmin(992) {
      margin-bottom: 4px; }
    &:last-child {
      @include rmin(768) {
        margin-bottom: 0; } }
    &:only-child {
      height: 100%; } }

  &__input {
    width: 100%;
    padding: 6px 9px 6px;
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    background-color: #ffffff;
    font-family: inherit;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: normal;
    color: #0b417a;
    appearance: none;
    font-size: 16px;
    @include rmin(992) {
      line-height: 19px;
      padding-right: 23px; }
    &::placeholder {
      color: inherit; }
    &--textarea {
      height: 109px;
      // height: 100%
      border-radius: 10.5px;
      resize: none;
      @include rmin(768) {
        min-height: 0;
        height: 100%;
        border-radius: 16px; } } }

  &__footer {
    display: flex;
    justify-content: flex-end; }

  &__submit {
    display: inline-block;
    min-width: 80px;
    padding: 6px;
    border: 1px solid #003874;
    border-radius: 16px;
    background-color: #003874;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    box-shadow: 0 2px 5px 0 rgba(67, 95, 147, 0.6);
    @include text-overflow;
    cursor: pointer;
    transition: all .3s;
    @include rmin(992) {
      min-width: 100px;
      font-size: 17px;
      line-height: 18px; }
    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874; } }

  label.error {
    // top: auto
    // bottom: 0
    line-height: 1;
    margin-top: -1em; } }





