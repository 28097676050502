/* PAGE: STATUS-REQUESTS */
.status-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

.request-details {
  background: white;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 46px;
  .close-head {
    padding: 20px 15px;
    display: flex;
    border-bottom: 2px solid #E5E5E5;
    align-items: center;
    img {
      margin-left: 20px; }
    .text {
      color: #003874;
      font-size: 20px;
      font-weight: bold; } }
  .dangerous {
    color: #ef393b; } }



/* Block: Intro section */
.status-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 16px;
  margin-bottom: 42px;
  @include rmin(992) {
    padding-top: 96px;
    margin-bottom: 26px; }

  &__row {
    @include rmin(992) {
      align-items: flex-end; } }

  &__col-text {
    margin-bottom: 24px;
    @include rmin(992) {
      margin-bottom: 0;
      padding-right: 48px; } }

  &__heading {
    margin-bottom: 0;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      font-size: 50px;
      margin-bottom: 8px; } } }


/* Block: status data */
.status-data {
  padding-top: 23px;
  @include rmin(992) {
    padding-top: 25px; }


  &__header {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    padding-left: 19px;
    padding-right: 19px;
    @include rmin(992) {
      padding-right: 31px;
      padding-left: 28px;
      margin-bottom: 0; } }

  &__date-col {
    padding-left: 10px;
    @include rmin(992) {
      font-size: 14px;
      padding-left: 7px; } }

  // #req-filter-form
  //   .data-filter
  //     &__inputs-row
  //       align-items: flex-end
  //     &__input
  //       +rmin(992)
  //         &.select-year
  //           width: 100px
  //         width: 160px
  //     &__buttons
  //       width: 100%
  //       &-row
  //         justify-content: flex-end



  .pane-content--requests {
    @include rmin(992) {
      padding: 5px 31px 44px 37px; } }

  /* Block: Requests table */
  .requests-table {
    border-radius: 4px;
    min-width: 900px;
    display: none;
    @include rmin(992) {
      display: block;
      min-width: auto; }
    &-block {
      overflow-x: auto; }
    .thead {
 }      // border-bottom: : solid 1px rgba(209, 216, 245, 0.6)
    .tbody {
      .tr {
        // border-bottom: solid 1px rgba(209, 216, 245, 0.6)
        padding-bottom: 13px; } }
    .tr {
      display: flex;
      flex-wrap: wrap;
      border-bottom: solid 1px #e5e5e5;
      // &:first-of-type
 }      //   border-top: none
    .th:first-of-type,
    .td:first-of-type {
      width: 19%; }
    .th:nth-of-type(2),
    .td:nth-of-type(2) {
      width: 12%;
      padding-right: 29px; }
    .td:nth-of-type(2) {
      letter-spacing: 0; }
    .th:nth-of-type(3),
    .td:nth-of-type(3) {
      width: 24%; }
    .th:nth-of-type(4),
    .td:nth-of-type(4) {
      width: 15%;
      padding-right: 34px; }
    .th:nth-of-type(5),
    .td:nth-of-type(5) {
      width: 15%;
      padding-right: 31px; }
    .th:nth-of-type(6),
    .td:nth-of-type(6) {
      text-align: left;
      width: 15%;
      padding-left: 25px; }
    .th {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.0175em;
      color: #464646;
      padding: 15px 27px 12px 0; }
    .td {
      font-size: 15px;
      font-weight: 400;
      color: #4c4c4c;
      letter-spacing: -.0175em;
      @include rmin(992) {
        padding: 24px 27px 7px 12px; }
      &.number {
        font-size: 12px;
        line-height: 2.1; }
      &--warning {
        color: #f5a726; }
      &--dangerous {
        color: #ef393b; } }
    .collapse-container {
      width: 100%; } }


  .concentration-table {
    display: block;
    @include rmin(992) {
      display: none; }
    .collapsed-td-container .collapsed-td {
      label {
        font-weight: 700;
        font-size: 14px; }
      span {
        font-weight: normal; } }
    .td {
      font-size: 15px;
      font-weight: 700;
      color: #4c4c4c;
      letter-spacing: -.0175em;
      @include rmin(992) {
        padding: 24px 27px 7px 12px; }
      &.number {
        font-size: 12px;
        line-height: 2.1; }
      &--warning {
        color: #f5a726; }
      &--dangerous {
        color: #ef393b; } }

    .th {
      font-size: 14px; }
    .concentration-table {}
    margin-bottom: 0;
    @include rmin(992) {
      border-bottom: solid 1px rgba(209, 216, 245, 0.6); }
    @include custom-table;
    @include rmin(992) {
      min-width: auto; }
    &-block {
      overflow-x: auto; }
    .caption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      caption-side: top;
      text-align: right;
      color: #0b417a;
      letter-spacing: 0;
      margin-bottom: 4px;
      @include rmin(992) {
        background: #f5f6fa;
        display: table-caption;
        padding: 10px 13px;
        margin-bottom: 0; } }
    &__title {
      font-size: 16px;
      line-height: 1.6;
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: -.0175em;
      @include rmin(992) {
        float: right;
        font-size: 20px; } }
    &__sum {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -.03em;
      @include rmin(992) {
        font-size: 25px;
        float: left;
        font-weight: 700; }
      span {
        font-size: 17px;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        margin-right: 3px;
        @include rmin(992) {
          margin-right: 8px;
          font-size: 29px; } } }
    .thead {
      border: 1px solid #e5e5e5;
      border-bottom: none;
      background: rgba(229,229,229,.39);
      border-radius: 2px 2px 0 0;
      @include rmin(992) {
        border-radius: 0;
        background: transparent;
        border: none; }
      & + .tbody {
        border-top: 0; } }
    .tbody {
      border: 1px solid #e5e5e5;
      @include rmin(992) {
        border: none; }
      .tr {
        border-top: 1px solid #e5e5e5;
        min-height: 70px;
        padding-bottom: 12px;
        padding-top: 13px;
        @include rmin(992) {
          padding-top: 0;
          padding-bottom: 0;
          border-top: none;
          min-height: auto; }
        &:first-of-type {
          border-top: none; } } }
    & > div:last-of-type {
      border-radius: 0 0 2px 2px;
      @include rmin(992) {
        border: 0; } }
    .tr {
      width: 100%;
      position: relative;
      flex-wrap: wrap;
      align-items: stretch; }
    .td, .th {
      letter-spacing: -.02em;
      &:first-of-type {
        @include rmin(992) {
          width: 10%;
          padding-right: 24px !important; } }
      &:nth-of-type(2) {
        @include rmin(992) {
          width: 8%; } }
      &:nth-of-type(3) {
        @include rmin(992) {
          width: 20% !important; } }
      &:nth-of-type(4) {
        @include rmin(992) {
          width: 10% !important;
          padding-right: 10px !important; } }
      &:nth-of-type(5) {
        @include rmin(992) {
          width: 26% !important;
          padding-left: 26px !important; } }
      &:nth-of-type(6) {
        @include rmin(992) {
          width: 14%;
          padding-right: 17px; } } }
    .th {
      display: none;
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      color: #464646;
      padding: 10px 7px;
      @include rmin(992) {
        font-size: 14px;
        border-top: solid 1px rgba(209, 216, 245, 0.6);
        display: table-cell;
        width: auto;
        padding: 28px 14px 7px 0; }
      &:first-of-type {
        display: inline-block;
        @include rmin(992) {
          display: table-cell; } } }
    .td {
      font-size: 15px;
      vertical-align: middle;
      line-height: 1;
      font-weight: 400;
      color: #4c4c4c;
      padding: 10px 7px 2px 0;
      // max-width: 39%
      flex-basis: 30%;
      &.inner-collaspe {
        label {
          display: none; }
        span {
          width: 100%; }
        .collapsible-list-container {
          width: 100%;
          padding: 0;
          margin: 0;
          .collapsible-list {
            padding: 0;
            .collapsible-list-item {
              display: flex;
              align-items: flex-start;
              padding: 0;
              .collapsible-list-item-title {
                font-size: 14px;
                width: 41%;
                font-weight: 700; }
              .collapsible-list-item-value {
                font-size: 14px;
                width: 59%; } } } } }
      &--dangerous {
        color: #ef393b; }
      @include rmin(992) {
        line-height: 1.2;
        padding: 10px 14px 5px 0;
        height: 50px;
        border-top: solid 1px rgba(209, 216, 245, 0.6);
 }        // max-width: 39%
      &.number {
        font-weight: 700;
        font-size: 11px;
        line-height: 20px;
        @include rmin(992) {
          line-height: 1.2;
          font-size: 14px;
          font-weight: 600;
          padding: 10px 14px 5px 0; } }
      &.td-button {
        text-align: right;
        padding: 7px 1px 2px 0;
        @include rmin(992) {
          text-align: left;
          padding: 10px 14px 5px 0; } }

      .link-details {
        display: inline-block;
        min-width: 82px;
        max-width: 100%;
        padding: 4px 10px;
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        color: #0b417a;
        font-size: 10.5px;
        line-height: 1.5;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0;
        @include rmin(992) {
          border: 1px solid #f7f8fb;
          font-size: 14px;
          min-width: 90px;
          background: #f7f8fb none; }
        @include text-overflow;
        transition: all .25s;
        &:focus,
        &:hover {
          border-color: #0b417a;
          text-decoration: none;
          background-color: transparent; } } }
    .collapsed-td {
      .td {
        font-size: 14px;
        align-items: center;
        line-height: 1.1;
        padding-left: 20px;
        &.number {
          font-weight: 600;
          font-size: 11px; }
        label {
          max-width: 41%;
          flex: 0 0 41%; } } }
    .row-toggle {
      left: 6px;
      top: 18px; } }





  .btn-toggle {
    padding: 8px 18px 7px 33px;
    border: none;
    border-radius: 20px;
    background-color: #e6ecf1;
    transition: all .3s;
    position: relative;
    color: #0b417a;
    min-width: 90px;
    font-weight: 700;
    font-size: 14px;
    &.btn-toggled {
      &:before {
        transform: rotate(135deg);
        left: 19px;
        top: 14px; } }
    &:before {
      content: '';
      width: 9px;
      height: 9px;
      border-bottom: 2px solid #0b417a;
      border-left: 2px solid #0b417a;
      position: absolute;
      top: 10px;
      left: 15px;
      transform: rotate(-45deg);
      transition: all .3s; }
    &--open {
      background-color: #0b417a;
      &:before {
        top: 44%;
        border-color: #fff;
        transform: rotate(135deg); } } }

  .info-btn {
    border: 1px solid #E5E5E5;
    color:#063D78 {}
    min-width: 90px;
    font-weight: 700;
    font-size: 15px;
    padding: 7px 1px 9px 0;
    border-radius: 20px;
    background: #FFFFFF; } }



.collapsible-list-container {
  padding: 15px 12px 19px;
  margin: 10px;
  @include rmin(992) {
    border: solid 1px #e5e5e5;
    border-radius: 8px;
    padding: 20px 16px 14px 16px;
    position: relative;
    background-image: #E5E5E5;
    background-size: 100% 40%;
    background-repeat: no-repeat;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid #e5e5e5;
      border-right: none;
      border-bottom: none;
      position: absolute;
      top: -10px;
      left: 5.2%;
      transform: rotate(45deg);
      background: white; } }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @include rmin(992) {
      justify-content: flex-end;
      margin-top: 0; }
    position: relative;
    .files-btn {
      border: 1px solid #E5E5E5;
      color:#063D78 {}
      min-width: 100px;
      font-weight: 700;
      font-size: 15px;
      padding: 7px 1px 9px 0;
      border-radius: 20px;
      background: #FFFFFF;
      @include rmin(992) {
        margin-left: 25px; } } }


  .collapsible-list {
    width: 100%;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    padding: 5px 16px 14px 10px;
    position: relative;
    background-size: 100% 40%;
    background-repeat: no-repeat;
    &-item {
      position: relative;
      z-index: 2;
      padding: 10px 0 0;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      min-height: 40px;

      &-title {
        display: inline-block;
        width: 40%;
        @include rmin(992) {
          width: 20%; }
        color: #4c4c4c;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -.0175em;
        vertical-align: top; }

      &-value {
        display: inline-block;
        vertical-align: top;
        width: 60%;
        padding-left: 6px;
        padding-right: 2px;
        font-size: 15px;
        font-weight: 400;
        color: #4c4c4c;

        a {
          color: #0b417a;
          text-decoration: underline;
          transition: .3s all;
          margin: 0 6px; } }

      .number {
        font-size: 11px;
        font-weight: 600; } }
    .dangerous {
      color: #ef393b; } }
  .docs-items {
    direction: inherit;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 40px;
    @include rmin(992) {
      top: 54px; }
    left: 5px;
    z-index: 101;
    width: 97%;
    text-align: right;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    @include rmin(992) {
      width: 150px;
      top: 40px;
      left: 0; }
    &.hide {
        display: none; }
    .item {
        // margin-bottom: 5px
        width: 100%;
        padding: 8px 28px 8px 10px;
        color: #4c4c4c;
        font-size: 15px;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        display: block;

        &:hover {
            background-color: rgba(229, 235, 241, 0.69);
            color: #0b417a;
            cursor: pointer; } } } }



