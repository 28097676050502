// Common styles

// html
//   // height: 100%
//   box-sizing: border-box
//   *, *:before, *:after
//     box-sizing: inherit
body {
  // padding: 0
  // margin: 0
  text-align: right;
  background-color: #ffffff;
  font-family: $mainFont;
  color: $mainDark;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  // -webkit-font-smoothing: antialiased
 }  // -moz-osx-font-smoothing: grayscale

/* Upgrading .modal-open class to work on safari */
.modal-open {
  overflow: hidden;
  position: relative;
  height: 100%;
  &:after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(black, .5);
    z-index: 1; }
  // .site-wrapper
 }  //overflow: hidden

/* BLOCK: top img(ducks)  */
.top-img {
  position: absolute;
  left: 0;
  right: -32%;
  top: 35px;
  overflow: hidden;
  @include rmin(576) {
    right: 52.5%;
    left: auto;
    top: -2px; }
  @include rmin(992) {
    display: block; }
  // +rmin(1200)
  //right: 54.5%
  //left: auto
  //top: 98px
  img {
    display: block;
    max-width: 50%;
    margin-right: auto;
    @include rmax(576) {
      margin-left: -6%; }
    @include rmin(1200) {
      height: 320px;
      max-width: 100%; } }

  &--small-ducks {
    right: 60%;
    top: 53px;
    img {
      max-height: 100%; } } }

/* Block: white sheet background */
.content-sheet {
  position: relative;
  box-shadow: -11px 9px 21px rgba(173, 182, 217, 0.17), -2px 5px 6px rgba(214, 217, 231, 0.29);
  border-radius: 0;
  background-color: #ffffff;
  @include rmin(992) {
    border-radius: 4px; } }

/* Pseudo-table styles*/
@mixin custom-table {
  width: 100%;
  @include rmin(992) {
    display: table; }

  .caption {
    @include rmin(992) {
      display: table-caption; } }

  .thead {
    @include rmin(992) {
      display: table-header-group; } }

  .tbody {
    @include rmin(992) {
      display: table-row-group; } }

  .tfoot {
    @include rmin(992) {
      display: table-footer-group; } }

  .tr {
    display: flex;
    @include rmin(992) {
      display: table-row; } }

  .th,
  .td {
    @include rmin(992) {
      display: table-cell; } }

  .th {
    font-weight: bolder; } }

// message counter
@mixin msgCount {
  display: inline-block;
  height: 12px;
  min-width: 12px;
  position: absolute;
  top: 4px;
  right: 7px;
  transform: translateX(50%);
  padding: 0 1px;
  border: 1px solid #fa4565;
  border-radius: 10px;
  background-color: #fa4565;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 9px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  z-index: 1;
  @include rmin(992) {
    top: 50%;
    margin-top: -17px;
    right: 24px;
    min-width: 16px;
    height: 16px;
    padding: 1px;
    font-size: 12px;
    line-height: 12px; } }

/* showing shadows on scrolled content */
@mixin scroll-indicator {
  background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, linear-gradient(rgba(0, 0, 0, 0.09), transparent), linear-gradient(to top, rgba(0, 0, 0, 0.09), transparent) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 30px, 100% 30px, 100% 17px, 100% 17px;
  background-attachment: local, local, scroll, scroll;
  @include rmin(992) {
    background: none; } }

.scroll-indicator {
  @include scroll-indicator; }


/* helper style for numbers */
.number {
  font-family: 'Open Sans', sans-serif; }

span.number {
  direction: ltr;
  display: inline-block; }

.nav-tabs {
  .nav-item {
    margin-bottom: 0; }
  .nav-link {
    border-radius: 6px 6px 6px 6px;
    @include rmin(992) {
      border-radius: 4px 4px 0 0; }
    &:focus,
    &:hover {
      border-color: transparent; }
    &.active {
      border-color: transparent; }
    &.show {
      .nav-link {
        border-color: transparent; } } } }



/* Block: check date */
.info-check-date {
  margin-bottom: 0;
  color: #4c4c4c;
  font-size: 10.5px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.03em;
  vertical-align: bottom;
  @include rmin(992) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -.0175em; }

  .number {
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0;
    margin-right: 8px;
    @include rmin(992) {
      margin-right: 8px;
      font-size: 12px;
      font-weight: 600; } } }


/* common custom select styles */
.search-select {
  width: 100%;
  &__dropdown-toggle {
    position: relative;
    width: 100%;
    height: auto;
    padding: 3px 14px 4px 38px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    background: white;
    color: #063d78;
    font-family: inherit;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    text-align: right;
    letter-spacing: -0.0175em;
    @include text-overflow;
    cursor: pointer;
    @include rmin(992) {
      padding: 6px 22px 6px 30px;
      font-size: 15px;
      line-height: 17px; }

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      margin: 0;
      border: none;
      border-bottom: 2px solid #979797;
      border-left: 2px solid #979797;
      position: absolute;
      top: 50%;
      // margin-top: -7px
      left: 16px;
      transform: translateY(-75%) rotate(-45deg);
      transition: all .3s; }

    .show > & {
      // background-color: #0b417a
      &:after {
        // top: 44%
        // border-color: #fff
        // margin-top: -4px
        transform: translateY(-50%) rotate(135deg); } }

    .number {
      font-size: 11px;
      @include rmin(992) {
        font-size: 13px; } } }

  &__dropdown-menu {
    // float: none
    // right: auto
    min-width: 100%;
    left: auto;
    text-align: right;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff; }

  &__dropdown-content {
    width: 100%;
    // max-width: 280px
    max-height: 140px;
    overflow-x: hidden;
    // +rmin(992)
 }    //// max-width: 324px

  &__dropdown-item {
    width: 100%;
    padding: 8px 28px 8px 10px;
    color: #4c4c4c;
    font-size: 14px;
    line-height: 15px;
    @include text-overflow;
    &:focus,
    &:hover,
    &:active {
      background-color: rgba(#e5ebf1, .69); }
    &.active,
    &.is-selected,
    &:hover {
      background-color: rgba(#e5ebf1, .69);
      color: #0b417a;
      font-weight: 400;
      .number {
        color: #0b417a;
        font-weight: 600; } }
    .number {
      margin-left: 2px;
      font-size: 12px;
      font-weight: 600;
      color: #505c65;
      @include rmin(992) {
        font-size: 13px; } } }
  .is-disabled:first-of-type {
    display: none; } }

.dropdown-menu--has-close {
  position: relative;
  padding-top: 27px;
  padding-bottom: 22px;
  .dropdown-content {
    @include rmin(992) {
      min-width: 324px; } } }


.dropdown-close {
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 8px;
  border: none;
  background: none;
  color: #505c65;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 7px;
    width: 2px;
    height: 20px;
    background-color: #505c65; }
  &:before {
    top: 7px;
    transform-origin: left top;
    transform: rotate(45deg); }
  &:after {
    bottom: 7px;
    transform-origin: left bottom;
    transform: rotate(-45deg); }
  &:hover {
    &:before,
    &:after {
      background-color: #003874; } } }



/* Fixing datepicker dropdown */
.datepicker-rtl {
  left: auto !important; }

/* Custom checkbox*/
.yalin-checkbox {
  &__label {
    position: relative;
    color: #4c4c4c;
    font-size: 14px;
    letter-spacing: -.0175em;
    margin: 0;
    padding-right: 35px;
    a {
      display: inline-block;
      color: #0b417a;
      font-size: 14px;
      letter-spacing: -.02em;
      font-weight: 400;
      text-decoration: underline;
      margin: 0 8px;
      transition: all .25s;
      // &:hover
 }      //   text-decoration: none
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      right: 11px;
      top: 2px;
      // transform: translateY(-50%)
      width: 14px;
      height: 14px;
      border: 1px solid rgba(1,1,1, 0.5);
      border-radius: 2px;
      cursor: pointer; }
    &:after {
      content: '';
      display: none;
      position: absolute;
      right: 13px;
      top: 4px;
      height: 7px;
      width: 10px;
      border-left: 2px solid rgba(1,1,1, 1);
      border-bottom: 2px solid rgba(1,1,1, 1);
      // border-color: rgba(1,1,1, 1)
      transform: rotate(-45deg); } }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    &:checked {
      & ~ .yalin-checkbox__label:after {
        display: block; } }
    &:focus {
      & ~ .yalin-checkbox__label:before {
        box-shadow: 0 0 0 2px rgba(blue, .5); } }
    &.error {
      & ~ .yalin-checkbox__label:before {
        box-shadow: 0 0 0 2px #ef393b; } } } }


/* BS Tooltip styles overrides */
$tooltip-arrow-width:  24px;
$tooltip-arrow-height: 12px;
$tooltip-arrow-color:  #fff;
// .tooltip
.chart-tooltip {
  font-family: $mainFont;
  text-align: right;
  &.show {
    opacity: 1; }
  // .arrow
  &__arrow,
  .arrow {
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height; }

  // .tooltip inner
  &__content {
    max-width: 258px;
    padding: 19px 18px 14px;
    color: #464646;
    background-color: #fff;
    border-radius: 8px;
    text-align: right;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2); }

  &__label {
    margin-bottom: 9px;
    font-size: 15px;
    line-height: 19px; }

  &__value {
    margin-bottom: 0;
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 19px; } }

// copied from BS
.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;
    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color; } } }

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color; } } }

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;
    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color; } } }

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color; } } }

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top; }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right; }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom; }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left; } }

/*login error*/
span.error {
  margin-bottom: 5px;
  max-width: 100%;
  align-self: center;
  font-size: 16px;
  font-style: normal;
  margin-right: 10px;
  color: #ef393b;
  @include text-overflow; }

/* Validation error styles */
label.error {
  margin-bottom: 3px;
  max-width: 100%;
  position: absolute;
  top: 100%;
  right: 10px;
  font-size: 14px;
  font-style: normal;
  color: #ef393b;
  @include text-overflow; }

input.error {
  border-color: #ef393b; }


/* Custome scrollbar styles */
.simplebar-offset {
  overflow: hidden; }

.simplebar-track.simplebar-vertical {
  width: 4px;
  background: #eaebed;
  border-radius: 12px;
  right: 15px; }

.simplebar-scrollbar {
  background: rgba(53,64,82, 0.2);
  width: 4px;
  right: 0;
  border-radius: 12px;
  &:before {
    display: none; } }


/* Overlay with ducks */
.main-overlay {
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%;
  min-height: 240px;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
  @include rmin(992) {
    top: 95px; }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    // bottom: 0
    height: 100%;
    background: radial-gradient(at 70% 50%, rgba(255,255,255, .9) 25%, rgba(255,255,255, 0) 75%);
    @include rmin(992) {
      display: none; } }

  &__ducks {
    position: relative;
    display: block;
    margin-right: auto;
    max-width: 100%;
    width: 270 / 320 * 100%;
    min-height: 0;
    height: auto;
    transform: rotate(-2deg) translate(4px, -4px);
    z-index: -1;
    @include rmin(992) {
      width: 509 / 1366 * 100%;
      left: 115 / 1366 * 100%;
      transform: none;
      z-index: 0; } } }



/* BLOCK: general form layout - data-filter */
.data-filter {
  position: relative;

  &__container {
    @include rmin(992) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; }
    &--requests {
      @include rmin(992) {
        padding-right: 7px; } } }

  &__row {
    margin-left: -7px;
    margin-right: -7px;
    @include rmin(992) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; } }

  &__inputs {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 19px;
    @include rmin(992) {
      flex-grow: 1;
      margin-bottom: 0; } }

  &__inputs-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px; }


  &__col {
    position: relative;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 200px;
    margin-bottom: 9px;
    padding-left: 7px;
    padding-right: 6px;
    @include rmin(992) {
      flex-grow: 0;
      flex-basis: auto;
      margin-bottom: 10px; }
    &--grow {
      @include rmin(992) {
        flex-grow: 1; } }
    &.disabled {
      opacity: 0.6;
      pointer-events: none; } }

  &__buttons {
    padding-left: 7px;
    padding-right: 7px; }

  &__buttons-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    justify-content: flex-end; }


  &__buttons-col {
    width: auto;
    max-width: 100%;
    // flex-grow: 1
    // flex-basis: 200px
    flex-grow: 0;
    flex-basis: auto;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 10px;
    @include rmin(992) {
      flex-grow: 0;
      flex-basis: auto;
      padding-left: 4px;
      padding-right: 4px; } }

  &__input {
    width: 100%;
    padding: 5px 15px;
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    background: #ffffff none;
    color: #0b417a;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-align: right;
    vertical-align: middle;
    letter-spacing: -.0175em;
    transition: all .25s;
    @include rmin(992) {
      width: 160px;
      padding: 5px 10px;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0; }
    &:focus,
    &:hover {
      border-color: #435f93;
      background-color: #f8f9fa;
      outline: none; }

    &--accounts {
      @include rmin(992) {
        width: 167px; } }
    &--small {
      @include rmin(992) {
        width: 110px; } }
    &--medium {
      @include rmin(992) {
        width: 130px; } }
    &--fullwidth {
      @include rmin(992) {
        width: 100%; } }

    // dropdown arrow styles
    &.dropdown-toggle {
      padding-left: 30px;
      &:after {
        // margin-top: -5px
        left: 17px;
        @include rmin(992) {
          left: 14px; } } }

    &.js-datepicker {
      padding-left: 35px;
      background-image: url("../img/icons/calendar-icon.svg");
      background-position: 14px center;
      background-repeat: no-repeat;
      background-size: 12px 13px;
      font-family: "Open Sans", sans-serif;
      font-size: 11px;
      font-weight: 600;
      @include rmin(992) {
        background-position: 12px center;
        background-size: 17px 19px;
        font-size: 14px; }
      &::placeholder {
        font-family: $mainFont;
        color: #0b417a;
        font-size: 12px;
        line-height: inherit;
        font-weight: 700;
        @include rmin(992) {
          font-size: 15px; } } }
    &--small.js-datepicker {
      padding-left: 25px;
      padding-right: 7px;
      background-position: 7px center; }

    &.number {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      & + .dropdown-menu {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
 } } }        // margin-top: 11px

  &__btn {
    display: block;
    // width: 100%
    min-width: 75px;
    max-width: 100%;
    padding: 4px 10px;
    border-radius: 16px;
    border: solid 1px #003874;
    background: #003874;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    box-shadow: 0 2px 6px 0 rgba(67, 95, 147, 0.6);
    @include text-overflow;
    cursor: pointer;
    transition: all .25s;
    @include rmin(992) {
      min-width: 100px;
      max-width: 150px;
      padding: 5px 10px;
      border-radius: 16px;
      font-size: 17px;
      line-height: 20px;
      box-shadow: 0 2px 6px 0 rgba(67, 95, 147, 0.6); }
    &:focus {
      outline: none; }
    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874; }

    &--reset {
      border-color: rgba(184, 191, 216, 0.1);
      background-color: rgba(184, 191, 216, 0.1);
      color: #0b417a;
      font-size: 14px;
      box-shadow: none;
      @include rmin(992) {
        min-width: 81px; } } }

  .datepicker {
    font-family: "Open Sans", sans-serif; } }

.pane-content {
  &__promo-block {
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
    &--reports {
      margin-top: 73px; } }
  &__promo-card {
    width: 100%;
    min-height: 96px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &__promo-banner {
    display: block;
    max-height: 200px;
    max-width: 100%; } }

// .dropdown-user-id
//& + .dropdown-menu
//  @include rmin(992)
//    width: 100%
//    max-width: 324px


/* Filter block */
.filter-block-wrapper {
  margin-bottom: 24px;
  padding: 0;
  border-radius: 12.5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  // box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0, 0, 0, 0.23)
  @include rmin(992) {
    margin-bottom: 61px;
    padding: 13px 15px 7px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    @include rmin(992) {
      margin-bottom: 38px; } }
  &.filter-open {
    @include r(992 - .02) {
      border-color: transparent;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0, 0, 0, 0.23); } } }



/* Filter block layout without filter form */
.filter-block {
  position: relative;
  // overflow: hidden
  &__heading {
    display: none;
    position: relative;
    margin-bottom: 13px;
    padding-right: 35px;
    background: url("../img/icons/filter-icon.png") right 9px center no-repeat;
    background-size: 18px 17px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #464646;
    @include rmin(992) {
      display: block; } }


  &__toggle {
    position: relative;
    display: block;
    width: 100%;
    padding: 6px 29px 5px 30px;
    border: none;
    border-radius: 12.5px;
    background: #fff url("../img/icons/filter-icon.png") right 9px center no-repeat;
    background-size: 11px 11px;
    font-size: 10.5px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: right;
    color: #0b417a;
    @include text-overflow;
    cursor: pointer;
    transition: all .3s;
    @include rmin(992) {
      display: none; }

    &:focus {
      outline: none; }
    // &:hover
    //background-color: rgba(#0b417a, .05)

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      margin: 0;
      border: none;
      border-bottom: 2px solid #0b417a;
      border-left: 2px solid #0b417a;
      position: absolute;
      top: 50%;
      left: 17px;
      transform: translateY(-25%) rotate(135deg);
      transition: all .3s; }

    &.collapsed {
      &:after {
        transform: translateY(-75%) rotate(-45deg); } }

    &.filter-active {
      padding-right: 12px;
      background-color: #003874;
      background-image: none;
      color: #fff; } }

  &__clear {
    position: absolute;
    height: 23px;
    width: 23px;
    top: 0;
    left: 10px;
    border: none;
    background: transparent url("../img/icons/clear-filter.svg") center no-repeat;
    background-size: 9px;
    z-index: 9; }


  &__collapse:not(.show) {
    @include rmin(992) {
      display: block; } }

  &__form {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 21px;
    padding-right: 21px;
    @include rmin(992) {
      padding: 0; } } }

.update-button-edit {
  display: inline;
  margin-right: 60%;
  margin-top: 20px;
  line-height: 15px;
  max-width: 200px;
  @include rmin(992) {
    margin-right: 77%; } }

