.common-action-page {
    padding-bottom: 26px;
    top: 633px;
    gap: 0px;
    background: #EFF4F8;

    @media (max-width: 992px) {
        top: 921px;
        gap: 0px;
        background: #EFF4F8;
    }

    .sub-title {
        // font-family: Open Sans Hebrew;
        font-size: 20px;
        font-weight: 700;
        line-height: 27.24px;
        text-align: left;
        color: #003784;
        width: 128px;
        height: 27px;
        top: 670px;
        left: 1339px;
        gap: 0px;
        margin-right: 6%;

        @media (max-width: 992px) {
            margin-right: 30%;
            padding-top: 0%;

        }

        padding-top: 15px;

    }

    .wrap-img-desc {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 20px;

        .image-view {
            padding-top: 10px;
            width: 240px;
            cursor: pointer;
        }
        .image-desc {
        
            cursor: pointer;

        }
    }

    .wrap-img-mobile {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .image-view {
            width: 170px;
            cursor: pointer;
            margin-top: 10px;
        }
          .image-desc {
        
            cursor: pointer;

        }
    }
}