/* PAGE: BALANCE */

.balance-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.balance-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 16px;
  margin-bottom: 42px;
  @include rmin(992) {
    padding-top: 95px;
    margin-bottom: 26px; }

  &__row {
    @include rmin(992) {
      align-items: flex-end; } }

  &__col-text {
    margin-bottom: 24px;
    @include rmin(992) {
      margin-bottom: 0;
      padding-right: 48px; } }

  &__heading {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      font-size: 50px;
      margin-bottom: 8px; } } }

.pane-content--balance {
  @include rmin(992) {
    padding: 15px 30px 44px 33px; } }


/* Block: balance data */
.balance-data {
  padding-top: 12px;
  @include rmin(992) {
    padding-top: 32px; }

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: 7px;
    padding-right: 0;
    @include rmin(992) {
      padding-right: 31px;
      padding-left: 28px;
      margin-bottom: 0;
      flex-direction: row; } }

  &__date-col {
    padding-left: 16px;
    @include rmin(992) {
      font-size: 14px;
      padding-left: 7px;
      margin-top: 5px;
      margin-bottom: 0; } }

  &__actions-col {
    position: relative;
    flex-grow: 1;
    padding-right: 17px;
    padding-left: 17px;
    @include rmin(992) {
      padding-left: 0;
      padding-right: 5px; } }

  // new: actions toggle
  &__actions-toggle {
    position: relative;
    display: block;
    // min-width: 83px
    max-width: 100%;
    height: 25px;
    padding: 11.5px;
    border: solid 1px #0b417a;
    border-radius: 15px;
    background-color: #0b417a;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 0;
    text-decoration: none;
    transition: all .25s;
    @include text-overflow;
    @include rmin(992) {
      display: none; }
    &:before,
    &:after {
      content: "";
      width: 1px;
      height: 11px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all .25s; }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .caption {
      display: none; }

    &.collapsed {
      padding: 5px 26px 5px 10px;
      border-color: #e5e5e5;
      background-color: transparent;
      background-image: url("../../img/icons/download-icon.svg");
      background-size: 11px 14px;
      font-size: 10.5px;
      line-height: 13px;
      font-weight: 700;
      letter-spacing: .03em;
      &:before,
      &:after {
        transform: scale(0); }
      .caption {
        display: inline; } } }


  // new: collapse for action buttons
  &__actions-collapse {
    position: absolute;
    // right: 0
    top: 100%;
    z-index: 9;
    @include rmin(992) {
      position: static;
      top: auto; }
    &:not(.show) {
      @include rmin(992) {
        display: block; } } } }

/* Block: concentration table */
.pane-content--balance {
  .concentration-table {
    margin-bottom: 0;
    @include rmin(992) {
      border-bottom: solid 1px rgba(209, 216, 245, 0.6); }
    @include custom-table;
    @include rmin(992) {
      min-width: auto; }
    &-block {
      overflow-x: auto; }
    .caption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      caption-side: top;
      text-align: right;
      color: #0b417a;
      letter-spacing: 0;
      margin-bottom: 4px;
      @include rmin(992) {
        background: #f5f6fa;
        display: table-caption;
        padding: 10px 13px;
        margin-bottom: 0; } }
    &__title {
      font-size: 16px;
      line-height: 1.6;
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: -.0175em;
      @include rmin(992) {
        float: right;
        font-size: 20px; } }
    &__sum {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -.03em;
      @include rmin(992) {
        font-size: 25px;
        float: left;
        font-weight: 700; }
      span {
        font-size: 17px;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        margin-right: 3px;
        @include rmin(992) {
          margin-right: 8px;
          font-size: 29px; } } }
    .thead {
      border: 1px solid #e5e5e5;
      border-bottom: none;
      background: rgba(229,229,229,.39);
      border-radius: 2px 2px 0 0;
      @include rmin(992) {
        border-radius: 0;
        background: transparent;
        border: none; }
      & + .tbody {
        border-top: 0; } }
    .tbody {
      border: 1px solid #e5e5e5;
      @include rmin(992) {
        border: none; }
      .tr {
        border-top: 1px solid #e5e5e5;
        min-height: 70px;
        padding-bottom: 12px;
        padding-top: 13px;
        @include rmin(992) {
          padding-top: 0;
          padding-bottom: 0;
          border-top: none;
          min-height: auto; }
        &:first-of-type {
          border-top: none; } } }
    & > div:last-of-type {
      border-radius: 0 0 2px 2px;
      @include rmin(992) {
        border: 0; } }
    .tr {
      width: 100%;
      position: relative;
      flex-wrap: wrap;
      align-items: stretch; }
    .td, .th {
      letter-spacing: -.02em;
      &:first-of-type {
        @include rmin(992) {
          width: 10%;
          padding-right: 24px !important; } }
      &:nth-of-type(2) {
        @include rmin(992) {
          width: 8%; } }
      &:nth-of-type(3) {
        @include rmin(992) {
          width: 20% !important; } }
      &:nth-of-type(4) {
        @include rmin(992) {
          width: 10% !important;
          padding-right: 10px !important; } }
      &:nth-of-type(5) {
        @include rmin(992) {
          width: 26% !important;
          padding-left: 26px !important; } }
      &:nth-of-type(6) {
        @include rmin(992) {
          width: 14%;
          padding-right: 17px; } } }
    .th {
      display: none;
      width: 39%;
      font-size: 12px;
      font-weight: bold;
      color: #464646;
      padding: 10px 7px;
      @include rmin(992) {
        font-size: 14px;
        border-top: solid 1px rgba(209, 216, 245, 0.6);
        display: table-cell;
        width: auto;
        padding: 28px 14px 7px 0; }
      &:first-of-type {
        display: inline-block;
        @include rmin(992) {
          display: table-cell; } } }
    .td {
      font-size: 15px;
      vertical-align: middle;
      line-height: 1;
      font-weight: 400;
      color: #4c4c4c;
      padding: 10px 7px 2px 0;
      // max-width: 39%
      flex-basis: 39%;
      @include rmin(992) {
        line-height: 1.2;
        padding: 10px 14px 5px 0;
        height: 50px;
        border-top: solid 1px rgba(209, 216, 245, 0.6);
 }        // max-width: 39%
      &.number {
        font-weight: 700;
        font-size: 11px;
        line-height: 20px;
        @include rmin(992) {
          line-height: 1.2;
          font-size: 12px;
          font-weight: 600;
          padding: 10px 14px 5px 0; } }
      &.td-button {
        text-align: right;
        padding: 7px 1px 2px 0;
        @include rmin(992) {
          text-align: left;
          padding: 10px 14px 5px 0; } }

      .link-details {
        display: inline-block;
        min-width: 82px;
        max-width: 100%;
        padding: 4px 10px;
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        color: #0b417a;
        font-size: 10.5px;
        line-height: 1.5;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0;
        @include rmin(992) {
          border: 1px solid #f7f8fb;
          font-size: 14px;
          min-width: 90px;
          background: #f7f8fb none; }
        @include text-overflow;
        transition: all .25s;
        &:focus,
        &:hover {
          border-color: #0b417a;
          text-decoration: none;
          background-color: transparent; } } }
    .collapsed-td {
      .td {
        font-size: 12px;
        align-items: center;
        line-height: 1.1;
        padding-left: 20px;
        &.number {
          font-weight: 600;
          font-size: 11px; }
        label {
          max-width: 41%;
          flex: 0 0 41%; } } }
    .row-toggle {
      left: 6px;
      top: 18px; } } }



.total-concentration {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #435f93;
  color: #0b417a;
  letter-spacing: 0;
  padding: 12px 8px 13px 12px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  @include rmin(992) {
    padding: 7px 13px 13px;
    box-shadow: none;
    border-top: 4px solid #435f93;
    background: #f5f6fa;
    border-radius: 0; }
  &__title {
    font-size: 16px;
    line-height: 1.6;
    font-weight: bold;
    margin: 0;
    @include rmin(992) {
      font-size: 20px; } }
  &__sum {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -.03em;
    padding-left: 3px;
    @include rmin(992) {
      font-size: 25px;
      padding-left: 0; }
    span {
      font-size: 17px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      margin-right: 1px;
      @include rmin(992) {
        margin-right: 8px;
        font-size: 29px; } } } }


/* Block: action buttons */
.action-buttons {
  list-style-type: none;
  width: 180px;
  margin: 0;
  margin-top: 9px;
  padding-right: 0;
  padding-top: 1px;
  padding-bottom: 4px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(148, 148, 148, 0.64);
  background-color: #fff;
  @include rmin(992) {
    width: auto;
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    padding: 0;
    border: none;
    box-shadow: none; }


  &__item {
    border-top: solid 1px rgba(#979797, .24);
    @include rmin(992) {
      border-top: none;
      margin-right: 5px; }
    &:first-of-type {
      border-top: none;
      @include rmin(992) {
        margin-right: 0; } } }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 18px 12px 10px;
    border: none;
    background-color: transparent;
    // background-position: right 20px center
    // background-repeat: no-repeat
    font-size: 17px;
    line-height: 25px;
    font-weight: bold;
    text-align: right;
    color: #0b417a;
    cursor: pointer;
    transition: all .25s;
    // outline: 1px dotted grey
    @include rmin(992) {
      padding: 5px 5px 5px 5px;
      font-size: 13px;
      line-height: 16px;
 }      // background-position: right 3px center
    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 6px;
      background-position: center;
      background-repeat: no-repeat;
      vertical-align: middle;
      @include rmin(992) {
        width: 25px;
        height: 25px;
        margin-left: 3px; } }

    &:focus,
    &:hover {
      text-decoration: none;
      color: #0b417a; }

    &--print {
      &:before {
        background-image: url("../../img/icons/print-icon.png");
        background-size: 25px 23px;
        @include rmin(992) {
          background-size: 22px 21px; } } }
    &--excel {
      &:before {
        background-image: url("../../img/icons/excel-icon.svg");
        background-size: 21px 27.5px;
        @include rmin(992) {
          background-size: 17px 21px; } } }
    &--pdf {
      &:before {
        background-image: url("../../img/icons/pdf-icon.png");
        background-size: 21px 27.5px;
        @include rmin(992) {
          background-size: 17px 21px; } } }

    span {
      font-size: 13px;
      font-family: 'Open Sans', sans-serif;
      margin-right: .3em;
      @include rmin(992) {
        font-size: 10px; } }

    // optionally
    img, svg {
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
      // margin-left: 7px
      max-width: 21px;
      height: auto;
      max-height: 21px; } } }






