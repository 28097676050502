/* PAGE: ACCOUNT-REPORT */

.account-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.account-intro {
  margin-bottom: 29px;
  padding-top: 16px;
  letter-spacing: .02em;
  color: #003770;
  @include rmin(992) {
    padding-top: 130px;
    margin-bottom: 32px; }
  &__error {
    text-align: right;
    display: block;
    margin-top: 5px;
    margin-right: 6px !important;
    @include rmin(992) {
      text-align: left; } }

  &__row {
    @include rmin(992) {
      align-items: flex-end; } }

  &__col-text {
    margin-bottom: 26px;
    @include rmin(992) {
      margin-bottom: 0;
      padding-right: 48px; } }

  &__heading {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      font-size: 40px;
      margin-bottom: 8px; } }

  &__subheading {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
    margin-top: 0;
    letter-spacing: -.0027em;
    @include rmin(992) {
      margin-bottom: 13px;
      font-size: 22px; }

    .number {
      font-size: 12px;
      font-weight: 700;
      @include rmin(992) {
        font-size: 17px; } } }

  &__list {
    padding-right: 0;
    list-style: none;
    margin-bottom: 8px;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-right: 13px;
      font-size: 11.5px;
      line-height: 1;
      letter-spacing: 0;
      align-items: baseline;
      flex-wrap: wrap;
      @include rmin(992) {
        padding-right: 20px;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: .0045em; }
      &:last-of-type {
        margin-bottom: 0; }
      &:before {
        content: '';
        position: absolute;
        right: 2px;
        top: 4px;
        width: 5.4px;
        height: 5.4px;
        border-radius: 50%;
        background: #003770;
        margin-left: 3.5px;
        @include rmin(992) {
          width: 7px;
          height: 7px;
          right: 2px;
          top: 7px; } }
      .number {
        margin: 0 2px;
        font-size: 9.5px;
        font-weight: 600;
        @include rmin(992) {
          font-size: 14px; } } } }

  &__control-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;
    @include rmin(992) {
      margin-left: -13px;
      margin-right: -13px; } }

  &__select-wrapper {
    width: 192px;
    padding-left: 9px;
    padding-right: 9px;
    @include rmin(992) {
      margin-left: 0;
      width: 231px;
      padding-left: 13px;
      padding-right: 13px; }

    .search-select__dropdown-toggle:after {
      left: 11px;
      @include rmin(992) {
        left: 16px; } } }

  &__control-col {
    flex-grow: 1;
    padding-left: 9px;
    padding-right: 9px;
    @include rmin(992) {
      flex-grow: 0;
      padding-left: 13px;
      padding-right: 13px;
 } }      // display: none

  &__download-btn {
    display: block;
    width: 100%;
    max-width: 95px;
    padding: 5px;
    padding-right: 26px;
    border: 1px solid #003874;
    border-radius: 20px;
    background-color: #003874;
    background-image: url("../../img/icons/download-report-icon.svg");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 11.5px 13px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    letter-spacing: -.0175em;
    box-shadow: 0 3px 7px 0 rgba(67, 95, 147, 0.6);
    transition: all .25s;
    @include text-overflow;
    cursor: pointer;
    @include rmin(992) {
      min-width: 105px;
      padding: 7px 5px;
      padding-right: 23px;
      font-size: 15px;
      background-size: 14px 16px; } }
  &__download-btn.loding-btn {
    background-image: url("../../img/loading.gif");
    background-color: #eff4f9;
    height: 28px;
    background-size: 40px 30px;
    background-position: center;
    &:hover {
      background-color: rgba(#003874, .5); } } }



/* Block: content */
.account-data {
  padding-top: 6px;
  @include rmin(992) {
    padding-top: 32px; }

  &__header {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0; //11px
    @include rmin(992) {
      align-items: center;
      padding-right: 28px;
      padding-left: 28px;
      margin-bottom: 0; }
    &:before {
      content: '';
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      background: #003874;
      @include rmin(992) {
        display: block; } } }

  &__date-col {
    padding-left: 2px;
    margin-bottom: 17px;
    @include rmin(992) {
      font-size: 14px;
      padding-left: 7px;
      margin-bottom: 10px; }
    &--general {
      margin-bottom: 13px;
      @include rmin(992) {
        margin-top: 10px;
        margin-bottom: 0; } }
    &--requests {
      margin-top: 21px;
      margin-bottom: 0; } }

  &__tabs-col {
    margin-left: -15px;
    margin-right: -15px;
    overflow-y: hidden;
    overflow-x: auto;
    @include rmin(992) {
      margin-left: auto;
      margin-right: 0; } }

  /* Block: tabs navigation */
  &__nav-tabs {
    padding-bottom: 11px;
    border-bottom: none;
    // max-width: 100%
    flex-wrap: nowrap;
    overflow: auto;
    // margin-left: auto
    &:after {
      content: "";
      width: 15px;
      flex-shrink: 0;
      @include rmin(992) {
        display: none; } }
    @include rmin(992) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0; } }

  &__nav-item {
    margin-left: 6px;
    // min-width: 76px
    @include rmin(992) {
      margin-left: 0;
 }      // min-width: auto
    &:last-of-type {
      margin-left: 0; } }

  &__nav-link {
    display: block;
    min-width: 76px;
    position: relative;
    text-align: center;
    font-size: 12px;
    background-color: rgba(11,65,122,.1);
    color: #435f93;
    white-space: nowrap;
    padding: 5px 10px;
    transition: .3s;
    border-radius: 6px;
    @include rmin(992) {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -.0175em;
      padding: 13px 17px;
      background-color: transparent;
      border-radius: 4px 4px 0 0;
      text-align: right; }
    @include rmin(1200) {
      padding: 13px 26px;
      min-width: 115px; }

    .nav-tabs &.active,
    .nav-tabs .show > &,
    .nav-tabs &:focus,
    .nav-tabs &:hover {
      background-color: rgba(11,65,122,1);
      color: #ffffff;
      @include rmin(992) {
        border-color: #003874 #003874 #fff;
        color: #0b417a;
        background-color: #ffffff; } } } }


/* Block: pane content */
.pane-content {
  padding: 18px 17px 11px;
  @include rmin(992) {
    padding: 33px 30px 72px 33px; }
  &--fund {
    padding: 19px 16px 11px 19px;
    @include rmin(992) {
      padding: 39px 33px 120px 33px; } }
  &--liquidity {
    @include rmin(992) {
      padding: 31px 33px 72px 33px; } }
  &--transactions {
    @include rmin(992) {
      padding: 40px 31px 69px 36px; } }
  &--management {
    @include rmin(992) {
      padding: 31px 30px 72px 33px; } } }


/* Block: Data section */
.data-section {
  margin-bottom: 26px;
  @include rmin(992) {
    margin-bottom: 107px; }
  &:last-of-type {
    margin-bottom: 0; }

  &--fund {
    margin-bottom: 25px;
    @include rmin(992) {
      margin-bottom: 71px; } }

  &--transactions {
    @include rmin(992) {
      margin-bottom: 81px; } }

  &__col {
    margin-bottom: 28px;
    @include rmin(992) {
      margin-bottom: 0; }
    &--concentration {
      margin-bottom: 0;
      @include rmin(992) {
        margin-bottom: 0; } } }

  &__header {
    // display: flex
    // justify-content: space-between
    // margin-bottom: 0
    // align-items: flex-end
    &--status {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 0
      // align-items: flex-end
      margin-bottom: 30px;
      align-items: start;
      @include rmin(992) {
        margin-bottom: 17px;
        align-items: flex-end; } }
    &--data {
      padding-bottom: 8px;
      border-bottom: solid 1px #e5e5e5;
      @include rmin(992) {
        padding-bottom: 8px;
        margin-bottom: 30px;
        border-bottom: solid 1px #e6e8f1; } }
    &--fund {
      @include rmin(992) {
        margin-bottom: 24px; } }
    &--abs {
      margin-bottom: 24px;
      @include rmin(992) {
        margin-bottom: 24px; } }
    &--liquidity {
      padding-bottom: 5px;
      @include rmin(992) {
        padding-bottom: 8px;
        margin-bottom: 21px; } }
    &--transactions {
      padding-bottom: 5px;
      @include rmin(992) {
        padding-bottom: 8px;
        margin-bottom: 21px; } }
    &--management {
      padding-bottom: 9px;
      @include rmin(992) {
        padding-bottom: 8px;
        margin-bottom: 23px; } } }

  &--concentration {
    margin-bottom: 30px;
    @include rmin(992) {
      margin-bottom: 54px; }
    &:nth-of-type(2) {
      margin-bottom: 37px;
      @include rmin(992) {
        margin-bottom: 51px; } }
    &-total {
      margin-bottom: 50px; } }




  &__header-row {
    position: relative;
    align-items: flex-end; }

  &__header-col {}

  &__date-col {
    margin-bottom: 5px;
    text-align: left;
    @include r(992 - .02) {
      position: absolute;
      bottom: -29px;
      left: 0;
      margin-bottom: 0; } }

  &__title {
    font-size: 19px;
    font-weight: 700;
    letter-spacing: -0.0175em;
    line-height: 1.2;
    margin-bottom: 0;
    // margin-top: 2px
    @include rmin(992) {
      // margin-top: 0
      font-size: 18px; }
    &--margin {
      @include rmin(992) {
        margin-bottom: 3px; } }
    &--data {
      font-size: 16px;
      @include rmin(992) {
        font-size: 19px; } }

    .number {
      font-size: 14px;
      margin-right: 7px;
      @include rmin(992) {
        font-size: 15px; } } }

  &__lead {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -.0175em;
    @include rmin(992) {
      line-height: 1.5; } }

  &__filter-wrapper {
    @include rmin(992) {
 }      // width: 92px
    .search-select {
      font-family: "Open Sans", sans-serif; }
    .dropdown-toggle {
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -.02em;
      width: 110px; } } }



/* Account-report table-containers*/
.account-table-block {
  margin-bottom: 32px;
  @include rmin(992) {
    margin-bottom: 56px; }
  &:last-of-type {
    margin-bottom: 0; }

  &__table-notice {
    display: none;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 1.8;
    color: #4c4c4c;
    margin-top: 22px;
    @include rmin(992) {
      display: block; }
    &:before {
      content: '*';
      font-size: 22px;
      margin-right: 4px;
      line-height: .5;
      vertical-align: bottom;
      margin-left: 5px; } } }



/* BLOCK: RESPONSIVE account table */
.account-table {
  @include custom-table;
  margin-bottom: 0;
  @include rmin(992) {
    display: table;
    border-radius: 0;
    border: none; }

  .thead {
    border: 1px solid rgb(229,229,229);
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    @include rmin(992) {
      border-radius: 0;
      border: none; }
    .tr {
      @include r(992 - .02) {
        padding-bottom: 0; } }
    & + .tbody {
      border-top: 0; } }

  .tbody {
    border: 1px solid rgb(229,229,229);
    @include scroll-indicator;
    max-height: 329px;
    overflow-y: auto;
    @include rmin(992) {
      max-height: none;
      border: none; }

    .tr {
      &:first-of-type {
        .td {
          @include r(992 - .02) {
            border-top: 0; } } } }
    .number {
      @include rmin(992) {
        line-height: 1.6;
        padding-bottom: 10px; } } }

  .tfoot {
    border: 1px solid rgb(229,229,229);
    border-top: 0;
    background: #ffffff;
    @include rmin(992) {
      border: none;
      background-color: #f5f6fa; }

    .tr {
      &:last-of-type {
        padding-bottom: 0; }

      .row-toggle {
        top: 13px; }

      .td:first-of-type {
        font-weight: 700; }

      .number:last-of-type {
        font-size: 12.5px;
        font-weight: 700;
        @include rmin(992) {
          font-size: 12px; } } }

    .td {
      font-weight: 700;
      color: #464646;
      padding-bottom: 15px;
      @include rmin(992) {
        padding-bottom: 23px;
        padding-top: 19px; }
      &:first-of-type {
        font-size: 14px;
        padding-right: 8px;
        @include rmin(992) {
          padding-right: 14px; } }
      &:last-of-type {
        font-size: 12.5px; } }

    .collapsed-td-container {
      .collapsed-td {
        .td {
          font-size: 12.5px; }
        label {
          font-size: 14px;
          font-weight: 700; } } } }

  & > div:last-of-type {
    border-radius: 0 0 4px 4px;
    @include rmin(992) {
      border: 0; } }
  .tr {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    padding-bottom: 14px;
    @include rmin(992) {
      padding-bottom: 0; }
    & > .td:last-of-type,
    & > .td:first-of-type {
      font-weight: 700;
      @include rmin(992) {
        font-weight: 400; } }
    & > .td:last-of-type {
      font-size: 11px;
      @include rmin(992) {
        font-weight: 600;
        font-size: 12px; } }
    &:last-of-type {
      .td {
        border-bottom: none; } } }
  .td {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    max-width: 50%;
    flex-basis: 50%;
    padding: 19px 7px 11px 0;
    align-items: center;
    line-height: 1.1;
    letter-spacing: -0.0175em;
    border-top: 1px solid rgba(209, 216, 245, 0.6);
    @include rmin(992) {
      line-height: 1.28;
      display: table-cell;
      font-size: 12px;
      color: #4c4c4c;
      text-transform: uppercase;
      vertical-align: middle;
      padding: 16px 4px; }
    &:nth-of-type(1) {
      width: 42%; }
    &:nth-of-type(2) {
      width: 19%; }
    &:nth-of-type(3) {
      width: 18%; }
    &:nth-of-type(4) {
      width: 14%; }

    .number {
      font-weight: 600; }
    &:first-child {
      font-size: 12px;
      @include rmin(992) {
        font-size: 15px; } }
    &:last-of-type {
      padding-right: 8px;
      @include rmin(992) {
        padding-right: 0; } }
    @include rmin(992) {
      line-height: 1.28; } }

  .th {
    font-size: 12px;
    display: none;
    width: 50%;
    background-color: rgba(229,229,229, .39);
    padding: 6px 10px 6px;
    letter-spacing: -0.02em;
    line-height: 1.8;
    color: #464646;
    @include rmin(992) {
      display: table-cell;
      width: auto;
      background: transparent;
      padding: 16px 4px 5px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5; }
    &:first-of-type,
    &:last-of-type {
      display: inline-block;
      @include rmin(992) {
        display: table-cell; } } } }

.table-caption {
  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.35;
  color: #464646;
  margin-bottom: 5px;
  @include rmin(992) {
    display: table-caption;
    margin-bottom: 1px; } }

.collapsed-td-container {
  width: 100%;
  order: 1;

  .collapsed-td {
    padding-bottom: 7px;

    .td {
      display: flex;
      //flex-wrap: wrap
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      border-top: none;
      font-size: 11px;
      padding-top: 5px;
      padding-bottom: 5px;
      &:last-of-type {
        padding-right: 7px; }
      label {
        font-size: 12px;
        max-width: 50%;
        flex: 0 0 50%;
        font-weight: 400;
        margin-left: 5px;
        color: #464646;
        margin-bottom: 0;
        font-family: $mainFont; } } } }

/* BUTTON: opening rows on mobile; added with JS */
.row-toggle {
  position: absolute;
  left: 8px;
  top: 22px;
  width: 37px;
  height: 25px;
  padding: 0;
  border: none;
  border-radius: 5px;
  background-color: #e6ecf1;
  transition: all .3s;
  cursor: pointer;
  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #0b417a;
    border-left: 2px solid #0b417a;
    position: absolute;
    top: 8px;
    left: 15px;
    transform: rotate(-45deg);
    transition: all .3s; }
  &--open {
    background-color: #0b417a;
    &:before {
      top: 11px;
      border-color: #fff;
      transform: rotate(135deg); } } }


/* BLOCK: simple stats table <table> */
.stats-table {
  width: 100%;
  color: #464646;

  caption {
    caption-side: top;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -.0175em;
    color: #464646;
    padding: 3px 0 3px;
    @include rmin(992) {
      padding: 2px 0 7px;
      font-size: 16px;
      letter-spacing: 0; } }

  td {
    font-size: 12px;
    font-weight: 400;
    padding: 6px 0 10px 44px;
    line-height: 1;
    letter-spacing: -.0175em;
    color: #464646;
    vertical-align: top;
    @include rmin(992) {
      padding: 10px 3px 10px 0;
      font-size: 15px; }
    &.number {
      font-size: 11px;
      padding-left: 17px;
      font-weight: 600;
      font-family: 'Open Sans', sans-serif;
      @include rmin(992) {
        font-size: 12px;
        padding-left: 9px;
        vertical-align: bottom; } } } }

/* Block: stats table container */
.stats-table-block {
  &--padded {
    @include rmin(992) {
      padding-right: 23px; }
    td.number {
      padding-left: 5px; } } }



/* === TAB 2 Content === */

.details-table-block {
  padding-top: 12px;
  @include rmin(992) {
    padding-top: 0; } }

.details-table {
  width: 100%;
  color: #464646;

  th, td {
    font-size: 14px;
    font-weight: 400;
    padding: 5px 5px 5px;
    line-height: 15px;
    letter-spacing: -.0175em;
    vertical-align: top;
    @include rmin(992) {
      padding: 10px 5px 10px;
      font-size: 15px;
      line-height: 15px;
      &:first-child {
        width: 42%; } }
    &:first-child {
      padding-right: 0; }
    &:last-child {
      padding-left: 0; } }

  .number {
    font-size: 11px;
    font-weight: 600;
    @include rmin(992) {
      font-size: 12px;
      letter-spacing: 0; } }

  @include r(992 - .2) {
    display: block;
    tbody, thead, tfoot {
      display: block; }
    tr {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between; }
    th, td {
      display: block;
      min-width: 45px; }
    td {
      text-align: left; } } }

// .route-table
//min-width: 600px
//width: 100%
//color: #464646
//@include rmin(992)
//  min-width: auto
//&-block
//  overflow-y: hidden
//  overflow-x: auto
//td
//  font-size: 15px
//  font-weight: 400
//  padding: 11px 0
//  line-height: 1
//  letter-spacing: -.0175em
//  &:nth-of-type(2)
//    width: 39%
//  &.number
//    font-size: 12px
//    letter-spacing: 0
//    font-weight: 600
//  .number
//    margin: 0 2px


/* === TAB 3 Content === */
.transactions-filter-block {
  margin-bottom: 61px;
  padding: 10px 15px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  &--reports {
    padding: 14px 15px 7px;
    margin-bottom: 38px; } }

.transactions-filter {
  &__heading {
    position: relative;
    margin-bottom: 14px;
    padding-right: 35px;
    background: url("../../img/icons/filter-icon.png") right 9px center no-repeat;
    background-size: 18px 17px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #464646; }
  &__collapse:not(.show) {
    @include rmin(992) {
      display: block; } } }


.transactions-table-block {
  overflow-x: auto;
  overflow-y: hidden;
  // +rmin(992)
  //margin-top: 62px

  &__table-notice {
    position: relative;
    margin-bottom: 0;
    padding-top: 6px;
    padding-right: 12px;
    border-top: 1px solid #e5e5e5;
    font-size: 12px;
    letter-spacing: -0.0175em;
    line-height: 12px;
    color: #464646;
    @include rmin(992) {
      padding-top: 0;
      border-top: none;
      padding-right: 20px;
      color: #4c4c4c; }
    &:before {
      content: '*';
      position: absolute;
      right: 2px;
      top: 50%;
      font-size: 19px;
      // line-height: .5
      vertical-align: middle;
      @include rmin(992) {
        font-size: 22px; } } } }

.transactions-table {
  // min-width: 900px
  width: 100%;
  color: #464646;
  margin-bottom: 45px;
  @include custom-table;
  @include rmin(992) {
    min-width: auto;
    margin-bottom: 32px; }

  .thead {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    background: rgba(229,229,229,.39);
    border-radius: 2px 2px 0 0;
    @include rmin(992) {
      border-radius: 0;
      background: transparent;
      border: none; }
    & + .tbody {
      border-top: 0; } }

  .tbody {
    border: 1px solid #e5e5e5;
    @include rmin(992) {
      border: none; }
    .tr {
      border-top: 1px solid #e5e5e5;
      min-height: 70px;
      padding-bottom: 12px;
      padding-top: 13px;
      @include rmin(992) {
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
        min-height: auto; }
      &:first-of-type {
        border-top: none; } } }
  & > div:last-of-type {
    border-radius: 0 0 2px 2px;
    @include rmin(992) {
      border: 0; } }
  .tr {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch; }
  .td, .th {
    letter-spacing: -.0175em;
    @include rmin(992) {
      &:first-of-type {
        width: 10.3%;
        padding-right: 2px; }
      &:nth-of-type(2) {
        width: 13%;
        padding-right: 15px; }
      &:nth-of-type(3) {
        width: 0%;
        padding-right: 0px; }
      &:nth-of-type(4) {
        width: 12%;
        padding-right: 0px; }
      &:nth-of-type(5) {
        width: 14%;
        padding-right: 3px; }
      &:nth-of-type(6) {
        width: 12.8%;
        padding-right: 2px; }
      &:nth-of-type(7) {
        width: 10%;
        padding-right: 0px; }
      &:nth-of-type(8) {
        padding-right: 0px; }
      &:nth-of-type(9) {
        width: 10%;
        padding-right: 0; } } }
  .th {
    display: none;
    width: 39%;
    font-size: 12px;
    font-weight: bold;
    color: #464646;
    padding: 10px 7px;
    // border-top: none
    @include rmin(992) {
      font-size: 14px;
      // border-top: solid 1px rgba(209, 216, 245, 0.6)
      display: table-cell;
      width: auto;
      padding: 19px 14px 7px 0; }
    &:first-of-type,
    &:last-of-type {
      display: inline-block;
      @include rmin(992) {
        display: table-cell; } } }
  .td {
    font-size: 15px;
    vertical-align: middle;
    line-height: 1;
    font-weight: 400;
    color: #4c4c4c;
    padding: 10px 7px 2px 0;
    max-width: 39%;
    flex-basis: 39%;
    @include rmin(992) {
      min-height: 51px;
      height: 51px;
      padding: 7px 14px 6px 0;
      border-top: solid 1px rgba(209, 216, 245, 0.6);
      vertical-align: middle; }
    &.number {
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      @include rmin(992) {
        font-size: 12px;
        font-weight: 600;
        padding: 7px 14px 4px 0; } }
    &.td-button {
      text-align: right;
      padding: 7px 1px 9px 0;
      @include rmin(992) {
        text-align: left;
        padding: 7px 14px 6px 0; } } }

  .collapsed-td {
    .td {
      min-height: 24px;
      font-size: 12px;
      align-items: center;
      line-height: 1.1;
      padding-left: 75px;
      &.number {
        font-weight: 600;
        font-size: 11px; }
      label {
        max-width: 53%;
        flex: 0 0 53%; } } }
  .row-toggle {
    left: 6px;
    top: 19px; }

  .tfoot {
    background: #f5f6fa;
    .td {
      min-height: 58px;
      @include rmin(992) {
        height: 58px;
        background: #f5f6fa; } } } }



/* === TAB 4 Content === */

.liquidity-table-block {
  overflow-x: auto;
  overflow-y: hidden; }

.liquidity-table {
  width: 100%;
  color: #464646;
  @include custom-table;
  @include rmin(992) {
 }    // min-width: auto

  .thead {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    background: rgba(229,229,229,.39);
    border-radius: 2px 2px 0 0;
    @include rmin(992) {
      border-radius: 0;
      background: transparent;
      border: none; }
    & + .tbody {
      border-top: 0; } }

  .tbody {
    border: 1px solid #e5e5e5;
    @include rmin(992) {
      border: none; }
    .tr {
      border-top: 1px solid #e5e5e5;
      min-height: 70px;
      padding-bottom: 12px;
      padding-top: 13px;
      @include rmin(992) {
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
        min-height: auto; }
      &:first-of-type {
        border-top: none; } } }

  & > div:last-of-type {
    border-radius: 0 0 2px 2px;
    @include rmin(992) {
      border: 0; } }
  .tr {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch; }
  .td, .th {
    letter-spacing: -.0175em;
    @include rmin(992) {
      &:nth-of-type(2) {
        width: 18%;
        padding-right: 8px; }
      &:last-of-type {
        width: 11%;
        padding-right: 7px; } } }
  .th {
    display: none;
    width: 39%;
    font-size: 12px;
    font-weight: bold;
    color: #464646;
    padding: 10px 7px;
    // border-top: none
    @include rmin(992) {
      font-size: 14px;
      // border-top: solid 1px rgba(209, 216, 245, 0.6)
      display: table-cell;
      width: auto;
      padding: 19px 14px 7px 0; }
    &:first-of-type,
    &:last-of-type {
      display: inline-block;
      @include rmin(992) {
        display: table-cell; } } }
  .td {
    font-size: 15px;
    vertical-align: middle;
    line-height: 1;
    font-weight: 400;
    color: #4c4c4c;
    padding: 10px 7px 2px 0;
    max-width: 39%;
    flex-basis: 39%;
    @include rmin(992) {
      min-height: 51px;
      height: 51px;
      padding: 7px 14px 6px 0;
      border-top: solid 1px rgba(209, 216, 245, 0.6);
      vertical-align: middle; }
    &.number {
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      @include rmin(992) {
        font-size: 12px;
        font-weight: 600;
        padding: 7px 14px 4px 0; } }
    &.td-button {
      text-align: right;
      padding: 7px 1px 9px 0;
      @include rmin(992) {
        text-align: left;
        padding: 7px 14px 6px 0; } } }

  .collapsed-td {
    .td {
      min-height: 24px;
      font-size: 12px;
      align-items: center;
      line-height: 1.1;
      padding-left: 75px;
      &.number {
        font-weight: 600;
        font-size: 11px; }
      label {
        max-width: 53%;
        flex: 0 0 53%; } } }
  .row-toggle {
    left: 6px;
    top: 19px; }

  .tfoot {
    background: #f5f6fa;
    .td {
      min-height: 58px;
      @include rmin(992) {
        height: 58px;
        background: #f5f6fa; } } } }




/* === TAB 5 Content === */
.management-table-block {
  overflow-x: auto;
  overflow-y: hidden; }

.management-table {
  width: 100%;
  color: #464646;
  // margin-bottom: 45px
  @include custom-table;
  @include rmin(992) {
    // min-width: auto
 }    // margin-bottom: 32px

  .thead {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    background: rgba(229,229,229,.39);
    border-radius: 2px 2px 0 0;
    @include rmin(992) {
      border-radius: 0;
      background: transparent;
      border: none; }
    & + .tbody {
      border-top: 0; } }

  .tbody {
    border: 1px solid #e5e5e5;
    @include rmin(992) {
      border: none; }
    .tr {
      border-top: 1px solid #e5e5e5;
      min-height: 70px;
      padding-bottom: 12px;
      padding-top: 13px;
      @include rmin(992) {
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
        min-height: auto; }
      &:first-of-type {
        border-top: none; } } }
  & > div:last-of-type {
    border-radius: 0 0 2px 2px;
    @include rmin(992) {
      border: 0; } }
  .tr {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch; }
  .td, .th {
    letter-spacing: -.0175em;
    @include rmin(992) {
      &:nth-of-type(2) {
        width: 14%;
        padding-right: 8px; }
      &:nth-of-type(3) {
        width: 16%;
        padding-right: 8px; }
      &:last-of-type {
        width: 11%;
        padding-right: 7px; } } }
  .th {
    display: none;
    width: 39%;
    font-size: 12px;
    font-weight: bold;
    color: #464646;
    padding: 10px 7px;
    // border-top: none
    @include rmin(992) {
      font-size: 14px;
      // border-top: solid 1px rgba(209, 216, 245, 0.6)
      display: table-cell;
      width: auto;
      padding: 19px 14px 7px 0; }
    &:first-of-type,
    &:last-of-type {
      display: inline-block;
      @include rmin(992) {
        display: table-cell; } } }
  .td {
    font-size: 15px;
    vertical-align: middle;
    line-height: 1;
    font-weight: 400;
    color: #4c4c4c;
    padding: 10px 7px 2px 0;
    max-width: 39%;
    flex-basis: 39%;
    @include rmin(992) {
      min-height: 51px;
      height: 51px;
      padding: 7px 14px 6px 0;
      border-top: solid 1px rgba(209, 216, 245, 0.6);
      vertical-align: middle; }
    &.number {
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      @include rmin(992) {
        font-size: 12px;
        font-weight: 600;
        padding: 7px 14px 4px 0; } }
    &.td-button {
      text-align: right;
      padding: 7px 1px 9px 0;
      @include rmin(992) {
        text-align: left;
        padding: 7px 14px 6px 0; } } }

  .collapsed-td {
    .td {
      min-height: 24px;
      font-size: 12px;
      align-items: center;
      line-height: 1.1;
      padding-left: 75px;
      &.number {
        font-weight: 600;
        font-size: 11px; }
      label {
        max-width: 53%;
        flex: 0 0 53%; } } }
  .row-toggle {
    left: 6px;
    top: 19px; }

  .tfoot {
    background: #f5f6fa;
    .td {
      min-height: 58px;
      @include rmin(992) {
        height: 58px;
        background: #f5f6fa; } } }



  // old
  // td,th
  //   &:nth-of-type(2)
  //     width: 14%
  //     padding-right: 8px
  //   &:nth-of-type(3)
  //     width: 16%
  //     padding-right: 8px
  //   &:last-of-type
  //     width: 11%
  //     padding-right: 7px
  // th
  //   font-size: 14px
  //   font-weight: 700
  //   padding: 5px 3px 9px
  //   line-height: 1
  //   letter-spacing: -.0175em
  //   border-bottom: 1px solid rgba(209, 216, 245, 0.6)
  // td
  //   height: 52px
  // tbody
  //   td
  //     border-bottom: 1px solid rgba(209, 216, 245, 0.6)
  // tfoot
  //   td
  //     background: #f5f6fa
 }  //     height: 57px



@include rmax(991) {
  .hidden {
    display: none !important; }
  .hidebelow992 {
    display: none !important; }
  .openedtr {
    .td {
      max-width: 25%; } }
  .hideabove992 {
    .th {
      max-width: 25% !important; } }
  .reports-table {
    .th {
      width: 25%; } }
  .hideView {
    display: none !important; }
  .openView {
    display: none;
    .closedView {
      .td {
        max-width: 25%; } } } }

@include rmin(991) {
  .hideabove992 {
    display: none !important; }
  .btn-group, .btn-group-vertical {
    width: 180px !important; }
  .multiselect-container>li>a>label.checkbox {
    padding: 0 15px 0 0 !important; }
  .multiselect-native-select {
    padding: 18px 10px !important; } }

@include rmin(992) {
  .resTitle {
    display: none !important; }
  .transactions-table .td:nth-of-type(2),
  .transactions-table .th:nth-of-type(2) {
    width: 10%;
    padding-right: 0px; } }

@include rmin(991) {
  .hidebelow992 {
 }    // display: none !important
  .transactions-table .tbody .tr {
    min-height: 45px !important; } }

.multiselect-container > li > a > label > input[type="checkbox"] {
    visibility: hidden; }

.page-link {
  color: #282828;
  font-size: 16px !important; }
.revaluationfilter {
  display: none; }
.nowrap {
  white-space: nowrap; }


.grecaptcha-badge {
  visibility: hidden; }

.multiselect-native-select {
  text-align: right;
  padding: 18px 30px;
  color: #4c4c4c; }

.btn-group,.btn-group-vertical {
  width: 160px;
  height: 32px;
  border-radius: 16px;
  border: solid 1.2px #003874;
  background-color: #f8f9fa;
  text-align: right;
  color: #0b417a;
  align-items: center; }

.multiselect-selected-text {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: right;
  color: #0b417a; }

.multiselect-native-select {
  .dropdown-toggle::after {
    border: solid #003874;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: .4s;
    position: absolute;
    left: 10px;
    top: 14px; }

  .show .dropdown-toggle::after {
    border: solid #003874;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(224deg);
    -webkit-transform: rotate(224deg); } }

.multiselect-container>li>a>label>input[type=checkbox] {
  margin-bottom: 5px;
  margin-left: 15px;
  width: 16px;
  height: 16px;
  position: absolute;
  height: .3px;
  width: .3px;
  z-index: -1; }

.multiselect-container>li>a>label.checkbox {
  padding: 0 25px 0 0;
  margin-bottom: 18px; }

.multiselect-native-select {
  select {
    display: none; }
  .dropdown-menu {
    text-align: right; }
  .multiselect-container {
    a {
     color: #505c65; } } }


.multiselect-container {
  .checkbox:before {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="grey"  width="24" height="24" viewBox="0 0 24 24"><path d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" /></svg>');
    position: relative;
    left: 5px;
    top: 5px;
    z-index: 100;
    zoom: 0.8; } }

.multiselect-container {
  .active .checkbox:before {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="navy" width="24"  height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>');
    position: relative;
    left: 5px;
    top: 5px;
    z-index: 100;
    zoom: 0.8; } }

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .multiselect-container .checkbox:before {
    content: "\00a0";
    position: relative;
    left: 5px;
    top: 5px;
    z-index: 100;
    zoom: 0.8;
    padding: 3px 7px 0px 6px;
    border: solid 2px silver;
    border-radius: 5px; } }

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .multiselect-container .active .checkbox:before {
    content: "\2713";
    position: relative;
    left: 5px;
    top: 5px;
    z-index: 100;
    border: solid 2px rgb(0, 0, 128);
    padding: 3px 0px 0px 1px;
    zoom: 0.8;
    color: white;
    border-radius: 7px;
    background: rgb(0,0,128); } }
