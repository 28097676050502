/* PAGE: LOGIN */

.login-main {
  position: relative;
  padding-top: 50px;
  padding-bottom: 0;
  background-image: url("../../img/bg-login.jpg");
  // background-position: 50% -20px
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  @include rmin(992) {
    padding-top: 70px;
    padding-bottom: 50px;
    background-position: center 65px; }

  &__container {
    padding-left: 0;
    padding-right: 0;
    @include rmin(992) {
      padding-left: 15px;
      padding-right: 15px; } } }


/* Block: login block container */
.login-wrapper {
  position: relative;
  padding-top: 102px;
  @include rmin(992) {
    // padding-left: 56px
    padding-top: 79px; } }

/* Block: login block */
.login-block {
  width: 100%;
  max-width: 423px;
  margin: 0 auto;
  // +rmin(992)
  //   max-width: 100%

  &__header {
    padding-right: 23px;
    padding-left: 23px;
    @include rmin(992) {
      padding-right: 6px;
      padding-left: 6px; } }

  &__heading {
    margin-bottom: 10px;
    font-size: 30px;
    color: #003770;
    letter-spacing: -.0175em;
    font-weight: bold;
    // padding-right: 6px
    @include rmin(992) {
      margin-bottom: 23px;
      font-size: 35px; } }

  &__subheading {
    margin-bottom: 9px;
    font-size: 10.5px;
    line-height: 1;
    letter-spacing: .002em;
    color: #4c4c4c;
    @include rmin(992) {
      margin-bottom: 6px;
      font-size: 14px;
      letter-spacing: .0175em; } }

  &__form-block {
    // min-height: 408px
    padding: 23px 40px 8px;
    background: #ffffff;
    @include rmin(992) {
      min-height: 408px;
      // min-width: 423px
      padding: 27px 43px 41px 43px;
      border-radius: 4px;
 } } }      //box-shadow: -11px 9px 21px 0 rgba(173, 182, 217, 0.17), -2px 5px 6px 0 rgba(214, 217, 231, 0.29)


.login__nav {
  &-tabs {
    padding: 0;
    box-shadow: 0 .5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 .5px 1px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-bottom: 32px;
    border: none;
    @include rmin(992) {
      margin-bottom: 20px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2); } }
  &-item {
    flex-grow: 1;
    flex-basis: 50%;
    // +rmin(992)
 }    //min-width: 119px
  &-link {
    display: block;
    height: 100%;
    padding: 7px 5px;
    border: none;
    color: rgba(70,70,70, .6);
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    letter-spacing: -.0175em;
    text-align: center;
    @include rmin(992) {
      padding: 13px 10px 12px;
      font-size: 15px;
      line-height: 1; }

    span {
      font-size: 10px;
      font-family: 'Open Sans', sans-serif;
      // display: inline-block
      // margin-right: 5px
      @include rmin(992) {
        font-size: 12px; } } } }

.login-tab-pane {
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin: -10px; }

.login-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  &__body {
    width: 100%;
    // margin-bottom: 9px
    // +rmin(992)
 }    //   margin-bottom: 20px //32px //55px

  &__footer {
    width: 100%;
    @include rmin(992) {
      display: flex;
      justify-content: space-between;
      align-items: center;
 }      // flex-direction: row
    // &--step2
 }    //justify-content: flex-end

  &__terms {
    position: relative;
    margin-bottom: 0;
    text-align: right;
    color: #4c4c4c;
    @include rmin(992) {
      margin-right: 10px;
      font-size: 14px; }
    a {
      margin-right: .3em;
      color: #0b417a;
      text-decoration: underline;
      letter-spacing: -.0175em;
      transition: all .25s;
      &:focus,
      &:hover {
        color: #0b417a;
        text-decoration: none; } } }

  &__form-group {
    position: relative;
    margin-bottom: 16px;
    @include rmin(992) {
      margin-bottom: 15px; }
    // &:last-of-type
 }    //margin-bottom: 0

  &__label {
    width: 100%;
    margin: 0; }

  &__field {
    width: 100%;
    padding: 7px 11px 6px;
    border: solid 1px #e5e5e5;
    border-radius: 15px;
    background-color: #fff;
    background-image: linear-gradient(transparent, transparent);
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: #0b417a;
    @include rmin(992) {
      border-radius: 20px;
      // height: 40px
      padding: 10px 19px;
      font-size: 20px;
      line-height: 18px; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(#0b417a, .25); }
    &::placeholder {
      color: #0b417a;
      opacity: 1; }
    &::-ms-input-placeholder {
      color: #0b417a; }
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none !important {
       margin: 0; } } }



  &__submit {
    display: block;
    width: 100%;
    padding: 8px 10px 7px;
    border: 1px solid #003874;
    border-radius: 20px;
    background: #003874;
    color: #ffffff;
    font-size: 15.5px;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6);
    @include text-overflow;
    transition: all .3s;
    @include rmin(992) {
      width: auto;
      max-width: 100%;
      padding: 9px 9px;
      min-width: 122.5px;
      border-radius: 20px;
      font-size: 20px;
      line-height: 1;
      box-shadow: 0 3px 8px 0 rgba(67, 95, 147, 0.6); }
    &:focus {
      outline: none; }
    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874; } }

  &__submit-container {
    margin-top: 57px;
    @include rmin(992) {
      margin-top: 0;
      margin-right: auto; }
    .login-form__footer--step2 & {
      margin-top: 43px;
      @include rmin(992) {
        margin-top: 0; } } }

  &__instruction {
    // max-width: 160px
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 25px
    font-size: 10.5px;
    line-height: 11px;
    text-align: center;
    color: #0b417a;
    @include rmin(992) {
      // max-width: 210px
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1; } }

  &__instruction-text {
    display: block; }

  &__instruction-number {
    display: block;
    // margin-top: 12px
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 14.5px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0.3158em;
    @include rmin(992) {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 19px;
      line-height: 20px; } }
  .radio-container {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
    input[type='radio'] {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      border: 1px solid black;
      border-radius: 50%;
      outline: none;
      &:hover {
        cursor: pointer; }
      &::before {
        content: '';
        display: block;
        width: 85%;
        height: 85%;
        margin: 7% auto;
        border-radius: 50%;
        color: black; }
      &:checked:before {
        background: #0b417a; } }

    .text-container {
      color: #0b417a;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 10px; }
    .form-check-inline {
      .form-check-input {
        margin-right: 0; }
      .form-check-label {
        margin-bottom: 0;
        margin-right: 5px;
        color: #0b417a; } } } }


.multi-step-form {
  min-height: 260px;
  padding-bottom: 12px;
  transition: .3s ease-in;
  opacity: 0;
  visibility: hidden;
  @include rmin(992) {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px; }

  &.step-active {
    opacity: 1;
    transform: translateX(0);
    visibility: visible; }

  &--step1 {
    transform: translateX(-100%);
    @include rmin(992) {
      padding-top: 21px; } }

  &--step2 {
    position: absolute;
    top: 3px; //10px
    left: 10px;
    right: 10px;
    bottom: 10px;
    transform: translateX(100%);
    @include rmin(992) {
      padding-top: 15px; } } }


.nav-tabs .login__nav-item {
  margin: 0; }

.nav-tabs .login__nav-link {
  border: none;
  border-radius: 0;
  transition: all .25s;
  &:focus,
  &:hover {
    color: #003874; } }

.nav-tabs .login__nav-link.active {
  background: #f0f4f7;
  color: #0b417a; }

