/* BLOCK: HEADER-LOGIN */

.yalin-login-collapse {
  @include rmin(992) {
    display: block !important;
    flex-basis: auto;
    align-self: flex-end; } }

.login-menu {
  @include rmin(992) {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 72px;
    &:after {
      content: "";
      width: 269px; //compensate logo
      flex-shrink: 1; } }
  &__navbar-nav {
    list-style-type: none;
    padding-right: 0;
    margin: 0;

    @include rmin(992) {
      flex-grow: 1;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end; } }

  &__nav-item {
    color: rgba(70,70,70, .6);
    @include rmin(992) {
      padding-left: 10px;
      padding-right: 10px; } }
  &__nav-link {
    display: block;
    position: relative;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 1;
    letter-spacing: -.0175em;
    color: inherit;
    font-weight: 700;
    transition: all .3s;
    @include rmin(992) {
      padding-top: 10px;
      padding-bottom: 17px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px; }

    &:after {
      // display: none
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 100%;
      background-color: rgba(70,70,70, .6);
      transition: all .3s;
      @include rmin(992) {
        width: 100%;
        height: 0;
        bottom: -1px; } }

    .active > & {
      color: #0b417a;
      background-color: rgba(#0b417a, .1);
      @include rmin(992) {
        background-color: transparent; }
      &:after {
        // display: block
        width: 3px;
        background-color: #0b417a;
        @include rmin(992) {
          width: 100%;
          height: 3px; } } }

    &:focus,
    &:hover {
      text-decoration: none;
      color: inherit;
      &:after {
        width: 3px;
        @include rmin(992) {
          width: 100%;
          height: 3px; } } } } }
