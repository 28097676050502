.customer-letter{
    .content-wrapper{
      .removeAll{
      margin-right: 800px;
      @media (max-width: 992px) {
       margin-right: 0px;
      }
      // bottom :43px;
      }

      .remove-all-letter{
        margin-right: 800px;
        @media (max-width: 992px) {
         margin-right: 0px;
         bottom: 0px;
         top:10px;
        }
         bottom :43px;
        }
      
      .content-sheet{
        box-shadow: none;
        .tabs-container{
          
        display: flex;
        position: relative;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0; //11px
        overflow: hidden;
        flex-direction: row;
        -ms-flex-direction: row-reverse;
  
        @media (min-width: 992px) {
          align-items: center;
          padding-right: 28px;
          padding-left: 28px;
          margin-bottom: 0;
        }
  
        &:before {
          content: '';
          display: none;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 1px;
          background: #003874;
  
          @media (min-width: 992px) {
            display: block;
          }
        }
    
     
  
    .nav-tabs{
      padding-bottom: 11px;
      border-bottom: none;
      flex-wrap: nowrap;

      &:after {
        content: '';
        width: 15px;
        flex-shrink: 0;

        @media (min-width: 992px) {
          display: none;
        }
      }

      @media (min-width: 992px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
 
     .nav-item{
      margin-left: 6px;

      // min-width: 76px
      @media (min-width: 992px) {
        margin-left: 0;
      }

      // min-width: auto
      &:last-of-type {
        margin-left: 0;
      }
        .nav-link{
          display: block;
          min-width: 76px;
          position: relative;
          text-align: center;
          background-color: rgba(11, 65, 122, 0.1);
          color: #435f93;
          white-space: nowrap;
          padding: 5px 10px;
          transition: 0.3s;
          border-radius: 6px;
    
          @media (min-width: 992px) {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.0175em;
            padding: 13px 17px;
            background-color: transparent;
            border-radius: 4px 4px 0 0;
            text-align: center;
          }
    
          @media (min-width: 1200px) {
            padding: 13px 26px;
            min-width: 115px;
          }
         
        &.active,
          .show > &,
          &:focus
        //  &:hover
          {
            background-color: rgba(11, 65, 122, 1);
            color: #ffffff;
    
            @media (min-width: 992px) {
              border-color: #003874 #003874 #fff;
              color: white;
              background-color: rgba(11, 65, 122, 1);
              
             // background-color: #ffffff;
            }
          }

          &:hover{
            @media (min-width: 992px) {
              border-color: #003874 #003874 #fff;
             
              
             // background-color: #ffffff;
            }
          }
        }
        }
     }  
    }
    
    
  }}
  .tab-content {
    .tab-pane{
        .reports-intro{
            padding-top: 0px;
            padding-bottom: 0px;
           
          
        }
        .content-wrapper{
        //  background-color: white;
          padding-right: 0px;
          padding-left: 0px;
        }
    }
  }
}