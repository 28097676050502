/* PAGE: RECENT ACTIONS */
.action-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.action-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 16px;
  margin-bottom: 42px;
  @include rmin(992) {
    padding-top: 96px;
    margin-bottom: 5px; }

  &__row {
    @include rmin(992) {
      align-items: flex-end; } }

  &__col-text {
    margin-bottom: 24px;
    @include rmin(992) {
      margin-bottom: 0;
      padding-right: 48px; } }

  &__heading {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      font-size: 50px;
      margin-bottom: 8px; } } }


/* Block: action data */
.action-data {
  &__sub-title {
    margin-bottom: 10px;
    line-height: 1;
    // font: Bold 12px/12px Almoni DL AAA
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0;
    color: #464646;
    @include rmin(992) {
      font-size: 24px;
      letter-spacing: .003em; } }
  // padding-top: 23px
  // +rmin(992)
  //   padding-top: 25px

  &__header {
    // display: flex
    // position: relative
    // align-items: center
    // flex-wrap: wrap
    // flex-direction: row-reverse
    // justify-content: space-between
    padding-left: 19px;
    margin-bottom: 11px;
    padding-right: 19px;
    padding-top: 10px;
    @include rmin(992) {
      padding-right: 31px;
      padding-left: 28px;
      margin-bottom: 20px;
      padding-top: 15px; } }

  &__date-col {
    padding-left: 10px;
    @include rmin(992) {
      font-size: 14px;
      padding-left: 7px;
      margin-top: 17px; } } }

.pane-content--actions {
  @include rmin(992) {
    padding: 5px 31px 44px 37px; } }


.data-filter__input {
  @include rmin(992) {
    width: 180px; } }
/* Block: actions table */
.actions-table {
  min-width: 900px;
  border-radius: 0;
  background: transparent;
  border: none;
  // display: none
  // +rmin(992)
  //   min-width: auto
 }  //   display: block

.actions-table-block {
  .concentration-table {
      margin-bottom: 0;
      @include rmin(992) {
        border-bottom: solid 1px rgba(209, 216, 245, 0.6); }
      @include custom-table;
      @include rmin(992) {
        min-width: auto; }
      &-block {
        overflow-x: auto; }
      .caption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        caption-side: top;
        text-align: right;
        color: #0b417a;
        letter-spacing: 0;
        margin-bottom: 4px;
        @include rmin(992) {
          background: #f5f6fa;
          display: table-caption;
          padding: 10px 13px;
          margin-bottom: 0; } }
      &__title {
        font-size: 16px;
        line-height: 1.6;
        font-weight: bold;
        margin-bottom: 0;
        letter-spacing: -.0175em;
        @include rmin(992) {
          float: right;
          font-size: 20px; } }
      &__sum {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -.03em;
        @include rmin(992) {
          font-size: 25px;
          float: left;
          font-weight: 700; }
        span {
          font-size: 17px;
          font-weight: 400;
          font-family: 'Open Sans', sans-serif;
          margin-right: 3px;
          @include rmin(992) {
            margin-right: 8px;
            font-size: 29px; } } }
      .thead {
        border: 1px solid #e5e5e5;
        border-bottom: none;
        background: rgba(229,229,229,.39);
        border-radius: 2px 2px 0 0;
        @include rmin(992) {
          border-radius: 0;
          background: transparent;
          border: none; }
        & + .tbody {
          border-top: 0; } }
      .tbody {
        border: 1px solid #e5e5e5;
        @include rmin(992) {
          border: none; }
        .tr {
          border-top: 1px solid #e5e5e5;
          min-height: 70px;
          padding-bottom: 12px;
          padding-top: 13px;
          @include rmin(992) {
            padding-top: 0;
            padding-bottom: 0;
            border-top: none;
            min-height: auto; }
          &:first-of-type {
            border-top: none; } } }
      & > div:last-of-type {
        border-radius: 0 0 2px 2px;
        @include rmin(992) {
          border: 0; } }
      .tr {
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        align-items: stretch; }
      .td, .th {
        letter-spacing: -.02em; }
      .th {
        display: none;
        width: 39%;
        font-size: 12px;
        font-weight: bold;
        color: #464646;
        padding: 10px 7px;
        @include rmin(992) {
          font-size: 14px;
          border-top: solid 1px rgba(209, 216, 245, 0.6);
          display: table-cell;
          width: auto;
          padding: 28px 14px 7px 0; }
        &:first-of-type {
          display: inline-block;
          @include rmin(992) {
            display: table-cell; } } }
      .td {
        font-size: 15px;
        vertical-align: middle;
        line-height: 1;
        font-weight: 400;
        color: #4c4c4c;
        padding: 10px 7px 2px 0;
        // max-width: 39%
        flex-basis: 29%;
        @include rmin(992) {
          line-height: 1.2;
          padding: 10px 14px 5px 0;
          height: 50px;
          border-top: solid 1px rgba(209, 216, 245, 0.6);
          max-width: 39%; }
        &.number {
          font-weight: 700;
          font-size: 11px;
          line-height: 20px;
          @include rmin(992) {
            line-height: 1.2;
            font-size: 12px;
            font-weight: 600;
            padding: 10px 14px 5px 0; } }
        &.td-button {
          text-align: right;
          padding: 7px 1px 2px 0;
          @include rmin(992) {
            text-align: left;
            padding: 10px 14px 5px 0; } }

        .link-details {
          display: inline-block;
          min-width: 82px;
          max-width: 100%;
          padding: 4px 10px;
          border: 1px solid #e5e5e5;
          border-radius: 20px;
          color: #0b417a;
          font-size: 10.5px;
          line-height: 1.5;
          font-weight: 700;
          text-align: center;
          letter-spacing: 0;
          @include rmin(992) {
            border: 1px solid #f7f8fb;
            font-size: 14px;
            min-width: 90px;
            background: #f7f8fb none; }
          @include text-overflow;
          transition: all .25s;
          &:focus,
          &:hover {
            border-color: #0b417a;
            text-decoration: none;
            background-color: transparent; } } }
      .collapsed-td {
        .td {
          font-size: 12px;
          align-items: center;
          line-height: 1.1;
          padding-left: 20px;
          &.number {
            font-weight: 600;
            font-size: 11px; }
          label {
            max-width: 41%;
            flex: 0 0 41%; } } }
      .row-toggle {
        left: 6px;
        top: 18px; } }
  .concentration-table {
    .thead {
      .th {
        width: 29%; } }
    @include rmin(992) {
      &-block {
        overflow-x: auto;
        .concentration-table {
          .thead {
            .th {
              font-weight: 700; } }
          .tbody {
            .tr {
              padding-bottom: 13px;
              border-top: 1px solid #e5e5e5; } }

          .th:first-of-type,
          .td:first-of-type {
            width: 18%; }
          .th:nth-of-type(2),
          .td:nth-of-type(2) {
            width: 12%;
            padding-right: 29px; }
          .td:nth-of-type(2) {
            letter-spacing: 0; }
          .th:nth-of-type(3),
          .td:nth-of-type(3) {
            width: 12%; }
          .th:nth-of-type(4),
          .td:nth-of-type(4) {
            width: 20%; }
          .th:nth-of-type(5),
          .td:nth-of-type(5) {
            width: 12%; }
          .th:nth-of-type(6),
          .td:nth-of-type(6) {
            width: 24%;
            padding-left: 25px; }
          .th {
            font-size: 14px;

            letter-spacing: -.0175em;
            color: #464646;
            padding: 15px 27px 12px 0; }
          .td {
            font-size: 15px;
            // font-weight: 700
            color: #4c4c4c;
            letter-spacing: -.0175em;
            padding: 24px 27px 7px 12px;
            &.number {
              font-size: 12px;
              line-height: 2.1; }
            &--warning {
              color: #f5a726; }
            &--dangerous {
              color: #ef393b; } } } } }
    .collapse-container {
      width: 100%; } } }










