.custom-table-block {
    overflow-x: auto;

    //background-color: red;
    .custom-table {
        width: 100%;
        display: table;
        border-bottom: solid 1px rgba(209, 216, 245, 0.6);

        @media (max-width: 992px) {
            margin-bottom: 0;
            width: 100%;
            display: block;
        }


        .thead {
            display: table-header-group;
            border-radius: 0;
            background: transparent;
            border: none;

            @media (max-width: 992px) {
                border: 1px solid #e5e5e5;
                border-bottom: none;
                background: rgba(229, 229, 229, 0.39);
                border-radius: 2px 2px 0 0;
                display: block;

            }

            .tr {
                width: 100%;
                position: relative;
                display: table-row;

                @media(max-width:992px) {
                    width: 100%;
                    position: relative;
                    flex-wrap: wrap;
                    align-items: stretch;
                    display: flex;

                }


                .th {
                    display: table-cell;
                    font-weight: bold;
                    color: #464646;
                    letter-spacing: -.02em;
                    font-size: 14px;
                    border-top: solid 1px rgba(209, 216, 245, 0.6);
                    display: table-cell;
                    width: auto;
                    padding: 29px 14px 7px 0;

                    @media(max-width: 992px) {

                        .th:first-of-type,
                        .reports-table .th:last-of-type {
                            display: inline-block;
                        }

                        width: 25%;
                        font-size: 12px;
                        font-weight: bold;
                        color: #464646;
                        padding: 10px 7px;
                        display: block;
                    }

                }

                .th.th-style {
                    padding-right: 31px;
                }
            }

        }

        .tbody {
            display: table-row-group;

            @media(max-width:992px) {
                div:last-of-type {
                    border-radius: 0 0 2px 2px;
                }

                display: block;

                border:1px solid #e5e5e5
            }

            .tr {
                width: 100%;
                position: relative;
                display: table-row;

                @media (max-width:992px) {
                    width: 100%;
                    position: relative;
                    flex-wrap: wrap;
                    align-items: stretch;
                    min-height: 70px;
                    padding: 5px 5px;
                    display: flex;
                    border-top: solid 1px black;

                    .tr:first-of-type {
                        border-top: none;
                    }
                }

                .td {
                    min-height: 51px;
                    height: 51px;
                    padding: 7px 14px 6px 0;
                    border-top: solid 1px black;
                    vertical-align: middle;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 7px 14px 4px 0;
                    display: table-cell;

                    @media(max-width: 992px) {
                        border-top: none;
                        // ont-size: 15px;
                        font-weight: 700;
                        font-size: 11px;
                        line-height: 15px;
                        letter-spacing: -.02em;
                        max-width: 25%;
                        color: #4c4c4c;
                        flex-basis: 39%;
                    }
                }

                .td.remove {
                    cursor: pointer;
                }

                td.td-button {
                    padding: 7px 14px 6px 0;

                    .save-pdf-btn {
                        background-position: right 13px center;
                    }
                }

                .td.bold-text {
                    font-weight: 900;
                }

                .td.td-gray-style {
                    background-color: #e5e5e5;

                    @media(max-width: 992px) {
                        background-color: transparent;
                    }

                }

                .td.td-blue-style {
                    background-color: lightblue;
                }

                .td.bold-text-name {
                    font-weight: 600;
                }

                .td.normal-text {
                    font-weight: 100;
                }
            }

            .tr.mobile-style {
                @media(max-width: 992px) {
                    background-color: #e5e5e5;
                }
            }

            .tr.mobile-style-blue {
                @media(max-width: 992px) {
                    background-color: lightblue;
                }
            }

        }
    }
}