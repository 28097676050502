/* PAGE: HOMEPAGE */

.home-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg_2.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg_2.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 71px;
    padding-bottom: 100px;
    background-size: 100% auto; }

  .top-img {
    @include r(992 - .02) {
      display: block; }
    @include r(576 - .02) {
      position: absolute;
      width: 270 / 320 * 100%;
      top: 46px;
      right: 50%;
      left: auto;
      transform: translateX(42%) rotate(-2deg);
      // &:after
      //content: ""
      //position: absolute
      //right: 0
      //left: 0
      //top: 0
      //// bottom: 0
      //height: 200%
 }      //background: radial-gradient(at 60% 50%, rgba(255,255,255, .9), rgba(255,255,255,0) 50%)

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100%;
      margin-right: 0;
      z-index: -1; } } }



.main-dashboard {
  position: relative;
  padding-top: 30px;
  padding-bottom: 29px;
  padding-left: 5px;
  padding-right: 5px;
  @include rmin(992) {
    // padding-top: 149px
    padding-top: 110px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: solid 1px rgba(#435f93, .24); }

  &__balance-note {
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 1.8;
    color: #003770;
    line-height: 1;
    font-weight: 700;
    letter-spacing: .03em;
    vertical-align: bottom;
    span {
      margin-left: 3px; } }

  &__welcome {
    margin-bottom: 22px;
    @include rmin(992) {
      display: none; } }

  &__welcome-person {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: .03em;
    color: #003770; }

  &__welcome-last-login {
    margin-bottom: 0;
    font-size: 10.5px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: .03em;
    color: #003770;
    vertical-align: bottom;
    .number {
      margin-right: .4em;
      font-size: 9px;
      letter-spacing: 0; } }



  &__header {
    margin-bottom: 10px;
    @include rmin(992) {
      margin-bottom: 26px;
      padding-right: 33px; } }

  &__heading {
    margin-bottom: 0;
    color: #003770;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      margin-bottom: 8px;
      font-size: 25px; }

    .number {
      display: block;
      margin-top: 2px;
      color: inherit;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      @include rmin(992) {
        margin-top: 4px;
        font-size: 60px;
        font-weight: 400; }
      &:before {
        content: "₪ ";
        font-family: $mainFont;
        @include rmin(992) {
          margin-right: -.2em; } } } }

  .totalTransactionAmount {
    @include rmin(992) {
      display: flex;
      margin-top: 26px; }
    .amount-style {
      font-size: 16px;
      @include rmin(992) {
        line-height: 30px;
        font-size: 25px; } } }



  &__charts-block {
    margin-bottom: 11px;
    @include rmin(992) {
      margin-bottom: 11px; } }

  &__charts-container {
    @include rmin(992) {
      padding-left: 18px;
      padding-right: 17px; } }

  &__charts-row {
    @include rmin(992) {
      margin-left: -18px;
      margin-right: -18px; } }

  &__charts-col {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    @include rmin(992) {
      flex-grow: 0;
      padding-left: 18px;
      padding-right: 18px; }

    // temp
    //&:nth-child(n+3)
    //@include r(992 - .02)
 }    //  display: none


  &__bottom {}

  &__bottom-row {}

  &__note {
    align-self: flex-end;
    flex: 1 0 auto;
    display: none;
    margin-bottom: 2px;
    padding-right: 8px;
    padding-left: 8px;
    color: #003770;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: .03em;
    vertical-align: bottom;
    @include rmin(992) {
      display: block; }

    .number {
      margin-right: .4em;
      font-size: 13px;
      letter-spacing: 0; } }

  &__btn-col {
    flex-grow: 1;
    text-align: center;
    @include rmin(992) {
      flex-grow: 0; } }

  &__btn {
    // width: 192.8px;
    // height: 38.6px;
    display: inline-block;
    max-width: 100%;
    padding: 5px 21px;
    border: 1px solid #003874;
    border-radius: 20px;
    background-color: #003874;
    box-shadow: 0 5px 13px 0 rgba(67, 95, 147, 0.6);
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: -.0175em;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    text-decoration: none;
    transition: all .3s;
    cursor: pointer;
    @include rmin(992) {
      padding: 8px 32px;
      font-size: 19px;
      line-height: 20px; }

    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874;
      text-decoration: none; } } }



/* Block: Chart-block*/
.chart-block {
  margin-bottom: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  @include rmin(992) {
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px; }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    max-width: 100%;
    width: 175px;
    height: 175px;
    @include rmin(992) {
      width: 194px;
      height: 194px;
      margin-bottom: 12px; } }

  &__heading {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: -.016em;
    color: #4c4c4c;
    text-align: center;
    @include rmin(992) {
      font-size: 20px; } } }


/* Block: Promo */
.main-promo {
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 23.5px;
  padding-right: 23.5px;
  @include rmin(992) {
    // padding-top: 48px
    // padding-bottom: 40px
    // padding-left: 0
    // padding-right: 0
    padding: 15px 0 15px; }

  &__row {
    @include rmin(1280) {
      margin-left: -19px;
      margin-right: -19px; } }

  &__col {
    // margin-bottom: 23px
    margin-bottom: 0;
    @include rmin(992) {
      // margin-bottom: 15px
 }      // margin-bottom: 0
    @include rmin(1280) {
      padding-left: 19px;
      padding-right: 19px; } }

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93px;
    padding: 12px 10px;
    border-radius: 2px;
    background-color: transparent;
    text-decoration: none;
    @include rmin(992) {
      height: 186px;
      padding: 0px;
      border-radius: 4px; }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%; } } }
.desktop-banner-view {
  .image-container {}
  position: relative;
  display: inline-block;
  .number-overlay {
    position: absolute;
    top: 25%;
    color: white;
    font-size: 30px;
    padding: 10px 25px 10px 10px; }
  .more-deatils-btn {
    position: absolute;
    top: 68%;
    padding: 0px;
    margin-right: 25px;
    .more-deatils-img {
      width: 300px; } }
  .deatils-btn {
    position: absolute;
    top: 69%;
    padding: 0px;
    right: 80%;
    .deatils-img {
      width: 180px; } } }

.mobile-banner-view {
  .image-container {
    position: relative;
    display: inline-block;
    .number-overlay {
      position: absolute;
      top: 15%;
      color: white;
      font-size: 25px;
      padding: 10px 20px 10px 10px; }
    .more-deatils-btn {
      position: absolute;
      top: 250px;
      padding: 0px;
      margin-right: 20px;
      .more-deatils-img {
        width: 150px; } }
    .deatils-btn {
     position: absolute;
     top: 85%;
     padding: 0px;
     right: 6%;
     .deatils-img {
       width: 130px; } } } }

