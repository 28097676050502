/* TYPOGRAPHY */

@font-face {
  font-family: 'Almoni Neue';
  src: url('../../fonts/almoni-neue-aaa-300-webfont.eot');
  src: url('../../fonts/almoni-neue-aaa-300-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/almoni-neue-aaa-300-webfont.woff2') format('woff2'),
       url('../../fonts/almoni-neue-aaa-300-webfont.woff') format('woff'),
       url('../../fonts/almoni-neue-aaa-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Almoni Neue';
  src: url('../../fonts/almoni-neue-aaa-400-webfont.eot');
  src: url('../../fonts/almoni-neue-aaa-400-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/almoni-neue-aaa-400-webfont.woff2') format('woff2'),
       url('../../fonts/almoni-neue-aaa-400-webfont.woff') format('woff'),
       url('../../fonts/almoni-neue-aaa-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Almoni Neue';
  src: url('../../fonts/almoni-neue-aaa-700-webfont.eot');
  src: url('../../fonts/almoni-neue-aaa-700-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/almoni-neue-aaa-700-webfont.woff2') format('woff2'),
       url('../../fonts/almoni-neue-aaa-700-webfont.woff') format('woff'),
       url('../../fonts/almoni-neue-aaa-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Almoni Neue';
  src: url('../../fonts/almoni-neue-aaa-900-webfont.eot');
  src: url('../../fonts/almoni-neue-aaa-900-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/almoni-neue-aaa-900-webfont.woff2') format('woff2'),
       url('../../fonts/almoni-neue-aaa-900-webfont.woff') format('woff'),
       url('../../fonts/almoni-neue-aaa-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} 

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.eot');
  src: url('../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/OpenSans-Regular.woff2') format('woff2'),
      url('../../fonts/OpenSans-Regular.woff') format('woff'),
      url('../../fonts/OpenSans-Regular.ttf') format('truetype'),
      url('../../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-SemiBold.eot');
  src: url('../../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/OpenSans-SemiBold.woff2') format('woff2'),
      url('../../fonts/OpenSans-SemiBold.woff') format('woff'),
      url('../../fonts/OpenSans-SemiBold.ttf') format('truetype'),
      url('../../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Bold.eot');
  src: url('../../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../../fonts/OpenSans-Bold.woff') format('woff'),
  url('../../fonts/OpenSans-Bold.ttf') format('truetype'),
  url('../../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* downloaded fonts */
 @font-face{
  font-family:'Almoni DL AAA';
  font-weight:300;
  font-style:normal;
  src:url('../../fonts/almoni-dl-aaa-light-webfont.woff') format('woff'),
    url('../../fonts/almoni-dl-aaa-light-webfont.ttf') format('truetype'),
    url('../../fonts/almoni-dl-aaa-light.otf') format('opentype');
}
@font-face{
  font-family:'Almoni DL AAA';
  font-weight:400;
  font-style:normal;
  src:url('../../fonts/almoni-dl-aaa-regular-webfont.woff') format('woff'),
    url('../../fonts/almoni-dl-aaa-regular-webfont.ttf') format('truetype'),
    url('../../fonts/almoni-dl-aaa-regular.otf') format('opentype');
}
@font-face{
  font-family:'Almoni DL AAA';
  font-weight:700;
  font-style:normal;
  src:url('../../fonts/almoni-dl-aaa-bold-webfont.woff') format('woff'),
    url('../../fonts/almoni-dl-aaa-bold-webfont.ttf') format('truetype'),
    url('../../fonts/almoni-dl-aaa-bold.otf') format('opentype');
}
@font-face{
  font-family:'Almoni DL AAA';
  font-weight:900;
  font-style:normal;
  src:url('../../fonts/almoni-dl-aaa-black-webfont.woff') format('woff'),
    url('../../fonts/almoni-dl-aaa-black.ttf') format('truetype'),
    url('../../fonts/almoni-dl-aaa-black.otf') format('opentype');
} 


/* test styles */
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw700 {
  font-weight: 700;
}
.fw900 {
  font-weight: 900;
}
