/* PAGE: tax-REPORTS */

.tax-reports-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 35px;
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.tax-reports-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 22px;
  padding-bottom: 71px;
  @include rmin(992) {
    padding-top: 100px;
    padding-bottom: 32px; }

  &__heading {
    max-width: 50%;
    margin-bottom: 0;
    font-size: 30px;
    line-height: .85;
    font-weight: 700;
    letter-spacing: -.0175em;
    color: #003770;
    @include rmin(992) {
      max-width: none;
      padding-right: 35px;
      font-size: 50px;
      line-height: 1;
      margin-bottom: 8px; } } }

.pane-content--tax-reports {
  padding-top: 12px;
  @include rmin(992) {
    padding: 31px 32px 44px 36px; } }



/* Block: tax-reports table */
.tax-reports-table-block {
  overflow-x: auto; }

.tax-reports-table {
  margin-bottom: 0;
  @include rmin(992) {
    border-bottom: solid 1px rgba(209, 216, 245, 0.6); }
  @include custom-table;
  // +rmin(992)
  //min-width: auto

  .thead {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    background: rgba(229,229,229,.39);
    border-radius: 2px 2px 0 0;
    @include rmin(992) {
      border-radius: 0;
      background: transparent;
      border: none; }
    & + .tbody {
      border-top: 0; } }

  .tbody {
    border: 1px solid #e5e5e5;
    @include rmin(992) {
      border: none; }
    .tr {
      border-top: 1px solid #e5e5e5;
      min-height: 70px;
      padding: 5px 5px;
      @include rmin(992) {
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
        min-height: auto; }
      &:first-of-type {
        border-top: none; } } }
  & > div:last-of-type {
    border-radius: 0 0 2px 2px;
    @include rmin(992) {
      border: 0; } }
  .tr {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 10px 5px; }
  .td, .th {
    letter-spacing: -.02em;
    &:first-of-type {
      @include rmin(992) {
        width: 15%; } }
    &:nth-of-type(2) {
      @include rmin(992) {
        width: 5%; } }
    &:nth-of-type(3) {
      @include rmin(992) {
        width: 50%; } }
    &:nth-of-type(4) {
      @include rmin(992) {
        width: 15%;
        padding-right: 10px; } } }
  .th {
    display: none;
    width: 39%;
    font-size: 12px;
    font-weight: bold;
    color: #464646;
    // padding: 10px 7px
    // border-top: none
    @include rmin(992) {
      font-size: 14px;
      border-top: solid 1px rgba(209, 216, 245, 0.6);
      display: table-cell;
      width: auto;
      padding: 29px 14px 7px 0; }
    &:first-of-type,
    &:last-of-type {
      display: inline-block;
      @include rmin(992) {
        display: table-cell; } } }
  .td {
    font-size: 15px;
    vertical-align: middle;
    line-height: 1;
    font-weight: 400;
    color: #4c4c4c;
    // padding: 10px 7px 2px 0
    max-width: 39%;
    flex-basis: 39%;
    @include rmin(992) {
      min-height: 51px;
      height: 51px;
      padding: 7px 14px 6px 0;
      border-top: solid 1px rgba(209, 216, 245, 0.6);
      vertical-align: middle; }
    &.number {
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      @include rmin(992) {
        font-size: 12px;
        font-weight: 600;
        padding: 7px 14px 4px 0; } }
    &.td-button {
      text-align: right;
      padding: 7px 1px 9px 0;
      @include rmin(992) {
        text-align: left;
        padding: 7px 14px 6px 0; } } }

  .collapsed-td {
    .td {
      min-height: 28px;
      font-size: 12px;
      align-items: center;
      line-height: 1.1;
      padding-left: 75px;
      &.number {
        font-weight: 600;
        font-size: 11px; }
      label {
        max-width: 53%;
        flex: 0 0 53%; } } }
  .row-toggle {
    left: 6px;
    top: 19px; } }


.save-pdf-btn {
  display: inline-block;
  min-width: 67px;
  max-width: 100%;
  padding: 5px 10px 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  background-color: transparent;
  background-image: none;
  color: #0b417a;
  font-size: 10.5px;
  line-height: 13px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0;
  @include text-overflow;
  transition: all .25s;
  @include rmin(992) {
    min-width: 90px;
    padding: 4px 30px 4px 13px;
    border: 1px solid #f7f8fb;
    background-color: #f7f8fb;
    background-image: url("../../img/icons/pdf-icon.png");
    background-position: right 13px center;
    background-repeat: no-repeat;
    background-size: 16px 21px;
    font-size: 14px;
    line-height: 20px; }
  &:focus,
  &:hover {
    border-color: #0b417a;
    text-decoration: none;
    background-color: transparent; }
  span {
    font-family: "Open Sans", sans-serif;
    font-size: 8px;
    font-weight: 700;
    @include rmin(992) {
      font-size: 11px;
 } } }      // margin-right: .1em

.data-section--tax-reports {
  margin-top: 70px; }

