/* PAGE: REPORTS */

.personal-main {
  background-color: #fafbfc;
  background-image: url("../../img/bg.jpg"), linear-gradient(180deg, rgba(#f5fbff, .5), rgba(245, 251, 255, 0));
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  // padding-bottom: 35px
  @include rmin(992) {
    background-image: url("../../img/bg.jpg"), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91));
    padding-top: 70px;
    padding-bottom: 100px; } }

/* Block: Intro section */
.personal-intro {
  letter-spacing: .02em;
  color: #003770;
  padding-top: 22px;
  padding-bottom: 50px;
  @include rmin(992) {
    padding-top: 100px;
    padding-bottom: 40px; }

  &__heading {
    max-width: 50%;
    margin-bottom: 0;
    font-size: 30px;
    line-height: .85;
    font-weight: 700;
    letter-spacing: -.0175em;
    @include rmin(992) {
      // margin-bottom: 8px
      max-width: none;
      padding-right: 33px;
      font-size: 50px;
      line-height: 1; } } }


.personal-data {
  padding: 26px 15px 55px 17px;
  @include rmin(992) {
    padding: 40px 30px 19px 35px; }

  &__block {
    @include r(992 - .02) {
      &:last-child {
        .personal-data__details {
          padding-bottom: 0;
          border-bottom: solid 1px rgba(209, 216, 245, 0.6); } } } }

  &__block-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 11px;
    border-bottom: solid 1px rgba(209, 216, 245, 0.6);
    @include rmin(992) {
      padding-bottom: 9px; } }

  &__heading {
    flex-grow: 1;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -.0175em;
    // color: #10457d
    @include rmin(992) {
      font-size: 18px;
      letter-spacing: .003em;
 } }      // color: #464646

  &__change-btn {
    // float: left
    display: block;
    min-width: 275 / 2 +px;
    // margin-bottom: -2px
    margin-right: auto;
    padding: 6px;
    border: 1px solid #003874;
    border-radius: 16px;
    background-color: #003874;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(67, 95, 147, 0.6);
    @include text-overflow;
    cursor: pointer;
    transition: all .3s;
    @include rmin(992) {
      min-width: 184px;
      margin-bottom: 3px;
      padding: 5px 6px;
      font-size: 17px;
      line-height: 18px; }
    &:focus,
    &:hover {
      background-color: #fff;
      color: #003874;
      text-decoration: none; } }


  &__details {
    padding-top: 12px;
    padding-bottom: 24px;
    @include rmin(992) {
      padding-top: 14px;
      padding-bottom: 32px; } }

  &__list {
    list-style-type: none;
    padding-right: 0;
    margin-bottom: 0;
    display: table;
    width: 100%;
    margin-bottom: 11px; }

  &__item {
    display: table-row; }
  &__item-type,
  &__item-value {
    display: table-cell;
    vertical-align: top;
    padding-top: 9px;
    padding-bottom: 9px;
    @include rmin(992) {
      padding-top: 9px;
      padding-bottom: 10px; } }

  &__item-type {
    position: relative;
    width: 252 / 573.5 * 100%;
    // padding-right: 33px
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: -.0175em;
    @include rmin(992) {
      width: 43%;
      padding-right: 2px;
      // padding-right: 40px
      font-size: 15px;
      line-height: 1; } }

  &__item-value {
    font-size: 12px;
    line-height: 12px;
    @include rmin(992) {
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -.0175em; }
    &.number,
    .number {
      font-size: 10.5px;
      font-weight: 600;
      letter-spacing: -.02em;
      @include rmin(992) {
        font-size: 12px;
 } }        // letter-spacing: -.0175em
    a {
      color: inherit; } } }









