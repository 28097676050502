/* BLOCK: SIDEBAR */

.site-sidebar {
  background-color: #fff;
  color: #435f93;
  @include rmin(992) {
    position: fixed;
    top: 0;
    right: 0;
    // height: 100vh
    width: $sidebarWidth+px;
    padding-top: 70px; // checked by JS if necessary
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18); }
  @include rmin($maxPageWidth + 20) {
    right: 0;
    // wrapper option
    // right: 50%
 }    // transform: translateX(($maxPageWidth / 2)+px)

  // .popup-open &
  //@include rmin(992)
 }  //  right: 17px

.yalin-navbar {

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    overflow-y: auto;
    @include rmin(992) {
      height: calc(100vh - 70px);
      scrollbar-width: thin;
      scrollbar-color: rgba(#003874,.2) transparent;
      scrollbar-face-color: rgba(#003874,.2);
      scrollbar-track-color: #003874;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
        width: 5px;
        height: 5px; } } }

  &__content-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    @include rmin(992) {
      flex-grow: 0; } }

  // &__section
  //@include rmin(992)
  //  position: relative
  //  margin-bottom: 20px
  //  background-color: #fff
  //  // &:after
  //  //   content: ""
  //  //   position: absolute
  //  //   top: 100%
  //  //   left: 0
  //  //   width: 100%
  //  //   height: 46px
  //  //   background-image: linear-gradient(180deg, #000, #ffffff)
  //  //   opacity: 0.09
  //  &:last-of-type
  //    &:after
  //      display: none

  &__promo {
    // margin-top: auto
    padding: 35px 20px 29px;
    order: 99;
    @include rmin(992) {
      margin-top: 0;
      padding: 6px 22px 20px; } } }

.welcome-block {
  padding: 19px 20px 12px;
  border-top: 1px solid rgba(186, 147, 0, 0.12);
  border-bottom: 1px solid rgba(186, 147, 0, 0.12);
  height: 80px;
  @include rmin(992) {
    padding: 14px 21px 17px;
    border-top: none;
    height: 100px; }
  &__person {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.03em;
    color: #000000;
    text-transform: uppercase;
    @include rmin(992) {
     padding-bottom: inherit; } }

  &__last-login {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: .0175em;
    text-transform: uppercase;
    vertical-align: bottom;
    color: #000000;

    @include rmin(992) {
      font-size: 14px;
      line-height: 0px;
      letter-spacing: .03em; }
    span.mobile-text {
      @include rmin(992) {
        display: none; } }
    span.desktop-text {
      @include r(992-.02) {
        display: none; } }
    time {
      margin-right: .5em;
      font-family: "Open Sans", sans-serif;
      font-size: 9px;
      letter-spacing: 0;
      @include rmin(992) {
        margin-right: .7em;
        font-size: 13px; } } } }

.promo-card {
  position: relative;
  display: block;
  padding: 9px 14px 13px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #ffffff;
  text-decoration: none;
  @include rmin(992) {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 7px rgba(0, 0, 0, 0.2);
    border-radius: 8px; }

  &__content {
    // display: flex
    // flex-wrap: nowrap
    // align-items: center
    position: relative;
    padding-right: 59px;
    padding-left: 10px;
    @include rmin(992) {
      padding-right: 54px;
 } }      // padding-left: 0

  &__img {
    max-width: 39px;
    // flex: 0 0 43px
    // margin-left: 11px
    position: absolute;
    right: 8px;
    bottom: -5px;
    @include rmin(992) {
      max-width: 43px;
      right: 0;
      bottom: 0; } }

  &__text {
    margin-top: 1px;
    margin-bottom: 0;
    // flex: 1 1 auto
    color: #003874;
    font-size: 21px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -.0175em;
    max-height: 5em;
    overflow: hidden;
    @include rmin(992) {
      margin-top: 9px;
      line-height: 1;
      letter-spacing: 0;
      max-height: 5em;
      overflow: hidden; } } }

.yalin-navbar-collapse {
  background-color: #fff;
  @include rmin(992) {
    display: block !important;
    width: 102%;
    height: auto;
    overflow-y: auto;
    // scrollbar-face-color: rgba(#232635,.2)
    // scrollbar-track-color: #232635
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
      // width: 5px
 } } }      // height: 5px

.yalin-navbar-nav {
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0;
  @include rmin(992) {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    // &:after
    //   content: ""
    //   position: absolute
    //   top: 100%
    //   left: 0
    //   width: 100%
    //   height: 46px
    //   background-image: linear-gradient(180deg, #000, #ffffff)
    //   opacity: 0.09
    &:last-of-type {
      &:after {
        display: none; } } }

  &--account {
    @include r(992 - .02) {
      order: 1; }
    li:first-child {
      @include r(992 - .02) {
        display: block; } } }

  .credit-item {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 164px;
      height: 97px;
      padding: 18px 9px 18px 20px;
      border-radius: 8px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      .text {
        color: #003874;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.1;
        margin-right: 10px; } } }

  .nav-item {
    // position: relative
    // padding: 0
    // border-bottom: 1px solid #ebedf4
    height: 56px;
    top: -158.5px;
    left: -1716px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    border-bottom: 1px solid #97B0D0;
    @include rmin($tablet-bp) {
      display: flex;
      padding: 0;
      border-bottom: 1px solid #97B0D0; }
    .welcome-block + & {} // refactor this???
    //  border-color: rgba(186, 147, 0, 0.12)
    .new {
      position: absolute;
      left: 0;
      height: 55.49px;
      width: 55.03px; }

    .menu-icon {
      left: 15px;
      position: absolute;
      margin-top: 23px; }
    .noReadIcon {
      background-color: #BF9100;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      font-size: 15px;
      line-height: 15px;
      color: white;
      text-align: center;
      position: absolute;
      right: 65%;
      margin-top: 20px; }
    .triangle {
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 26px solid transparent;
      border-bottom: 26px solid #3b3636;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: -4px;
      &::before {
        content: '';
        border-left: 25px solid transparent;
        border-right: 26px solid transparent;
        border-bottom: 26px solid#da3b3b;
        transform: rotate(0deg);
        position: absolute;
        right: -25px;
        top: -2px; }
      .text {
        position: absolute;
        right: -12px;
        top: 9px;
        font-size: 13px;
        font-weight: 600;
        color: white; } }

    .active {
      position: relative;
      @include rmin(992) {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 5px;
          border-left: 5px solid #003874;
          transition: width .25s;
          z-index: 1; } }

      .nav-link {
        background-color: rgba(#003874, .1); } } }

  .nav-item.big-menu {
    width: 100%;
    height: 28%;
    border: 1px 0px 0px 0px; }

  .nav-link {
    position: relative;
    align-items: center;
    width: 100%;
    padding: 8px 27px 7px 10px;
    color: inherit;
    text-decoration: none;
    line-height: 40px;
    text-align: right;
    // overflow: hidden
    transition: all .25s;
    cursor: pointer;
    @include rmin(992) {
      //min-height: 60px
      display: flex;
      padding: 12px 27px 12px 15px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px; }
    &:hover {
      background-color: rgba(#003874, .1);
      text-decoration: none;
      color: inherit; }

    svg {
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      width: 24px;
      height: 24px;
      line-height: 1;
      flex-shrink: 0;
      transform: translate(0, -50%);
      fill: rgb(175, 178, 189);
      @include rmin(992) {
        width: 32px;
        height: 32px;
        // margin-top: -16px
        right: 24px;
        fill: #435f93; } }
    .sidebar-text {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      color: #1D1D26;
      padding-right: 5px; } }
  .sidebar-text-open {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    padding-right: 10px;
    color: #144B95; }
  .sidebar-sub-text {
    padding: 0 47px 10px 0;
    color: #144B95; }
  .sub-nav-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1px 52px 7px 10px; // Default padding for larger screens
    color: inherit;
    text-decoration: none;
    text-align: right;
    transition: all 0.25s;
    background: white;
    cursor: pointer;
    &:hover {
      background-color: rgba(#003874, .1);
      text-decoration: none;
      color: inherit; }
    &:after {
      display: none; }
    /* individual icon sizes */
    .icon-messages {
      @include rmin(992) {
        width: 27px;
        height: 20px; } }
    .sidebar-text {
      font-size: 97%;
      margin-bottom: 5px; }
    .icon-balance {
      @include rmin(992) {
        width: 29px;
        height: 28px; } } }

  .wrap-nav {
    height: 45px;
    cursor: pointer;
    padding-top: 15px;
    padding-right: 27px;

    .menu-icon-open {
      left: 15px;
      position: absolute; } }
  .text-image {
    margin-top: 23px;
    .icon-loans {
      @include rmin(992) {
        width: 26px;
        height: 30px; } }
    .hide-loan {
      display: none; }

    .icon-info {
      @include rmin(992) {
        width: 29px;
        height: 30px; } }
    .icon-status {
      @include rmin(992) {
        width: 25px;
        height: 28px; } }
    &__msg-count {
      @include msgCount; } } }
