.forgot-password-container {
    padding-top: 5px;

    .login-block__heading {
        color: #0b417a;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 0;
    }

    .login-block__subheading {
        max-width: 259px;
        height: 74.5px;
        font-size: 15px;
        letter-spacing: normal;
        text-align: center;
        color: #0b417a;
        margin: auto;
    }
}